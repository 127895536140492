import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import {
  HomeOutlined,
  InfoOutlined,
  // InsertChartOutlined,
  LockOutlined,
  ChevronRightOutlined,
  // TableChart,
  SupervisorAccountOutlined,
  // MonitorHeart,
  Brightness4,
  Brightness7,
  ChevronLeft,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "../redux/global";
import { Icon } from "@iconify/react";

const Navbar = ({
  showAdminBoard,
  showMIBoard,
  // showModeratorBoard,
  currentUser,
  logOut,
  isNavbarOpen,
  setIsNavbarOpen,
  isNonMobile,
  drawerWidth,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const navItems = [
    {
      text: "Home",
      route: "",
      icon: <HomeOutlined />,
      necessary: true,
      external: false,
    },
    {
      text: "About",
      route: "about",
      icon: <InfoOutlined />,
      necessary: true,
      external: false,
    },
    // {
    //   text: "Cardio Project",
    //   route: "https://publish.obsidian.md/cardio-project/Roadmap",
    //   icon: <MonitorHeart />,
    //   necessary: true,
    //   external: true,
    // },
    // {
    //   text: "Cardio Parameters",
    //   route: "cardioParameters",
    //   icon: <Icon icon="icon-park-outline:heart" width={"24"} />,
    //   necessary: true,
    //   external: false,
    // },
    // {
    //   text: "Results",
    //   route: "results",
    //   icon: <Icon icon="uil:chart-line" width={"24"} />,
    //   necessary: true,
    //   external: false,
    // },
    {
      text: "ICD Project",
      route: "https://icdproject.win",
      icon: <Icon icon="material-symbols:pacemaker" width={"24"} />,
      necessary: false,
      external: true,
    },
    {
      text: "Emfragma Project",
      route: "https://emfragma.win",
      icon: <Icon icon="game-icons:heart-beats" width={"24"} />,
      necessary: false,
      external: true,
    },
    // {
    //   text: "MI Project",
    //   route: "https://miproject.win",
    //   icon: <Icon icon="material-symbols-light:heart-broken" width={"28"} />,
    //   necessary: true,
    //   external: true,
    // },
    {
      text: "MI Form",
      route: "miform",
      icon: <Icon icon="material-symbols-light:heart-broken" width={"28"} />,
      necessary: showMIBoard,
      external: false,
    },
    {
      text: "MI Table",
      route: "mitable",
      icon: <Icon icon="material-symbols-light:heart-broken" width={"28"} />,
      necessary: showMIBoard,
      external: false,
    },
    {
      text: "MI Statistics",
      route: "mistats",
      icon: <Icon icon="material-symbols-light:heart-broken" width={"28"} />,
      necessary: true,
      external: false,
    },
    // {
    //   text: "Enter Patient",
    //   route: "enter_patient",
    //   icon: <Icon icon="mdi:patient-outline" width={"24"} />,
    //   necessary: false,
    //   external: false,
    // },
    // {
    //   text: "Measurement Table",
    //   route: "measurement_table",
    //   icon: <TableChart />,
    //   necessary: false,
    //   external: false,
    // },
    // {
    //   text: "Measurement Plot",
    //   route: "measurement_plot",
    //   icon: <InsertChartOutlined />,
    //   necessary: false,
    //   external: false,
    // },
    {
      text: "Make Admin",
      route: "admin",
      icon: <SupervisorAccountOutlined />,
      necessary: showAdminBoard,
      external: false,
    },
    // {
    //   text: "Moderator Board",
    //   route: "mod",
    //   icon: <ShieldOutlined />,
    //   necessary: showModeratorBoard,
    // },
    // {
    //   text: "User",
    //   route: "user",
    //   icon: <PersonOutlineOutlined />,
    //   necessary: currentUser,
    // },
  ];

  return (
    <Box component="nav">
      {isNavbarOpen && (
        <Drawer
          open={isNavbarOpen}
          onClose={() => setIsNavbarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 1rem 1rem">
              <Box
                display="flex"
                alignItems="center"
                justifyItems="center"
                alignContent="center"
                justifyContent="center"
                gap="0.5rem"
              >
                <img src={"/logo512.png"} alt="Logo" width="200px" />
              </Box>
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    HeisenbergK
                  </Typography>
                </Box>
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => dispatch(setMode())}
                  color="inherit"
                >
                  {theme.palette.mode === "dark" ? (
                    <Brightness7 />
                  ) : (
                    <Brightness4 />
                  )}
                </IconButton>
                {!isNonMobile && (
                  <IconButton
                    onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                    sx={{ ml: 1 }}
                    color="inherit"
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, route, icon, necessary, external }) => {
                if (necessary) {
                  if (!icon) {
                    return (
                      <Typography key={text} sx={{ m: "2.5rem 0 1rem 3rem" }}>
                        {text}
                      </Typography>
                    );
                  }
                  // const lcText = text.toLowerCase();
                  const lcText = route;
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (!external) {
                            navigate(`/${lcText}`);
                            setActive(lcText);
                          } else {
                            window.open(lcText, "_blank", "noreferrer");
                          }
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary[300]
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
            position="absolute"
            bottom="0rem"
          >
            <Divider />
            {currentUser ? (
              <Box
                textTransform="none"
                m="1rem 1rem 1rem 1rem"
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <Typography
                    fontWeight="bold"
                    fontSize="1.2rem"
                    sx={{ color: theme.palette.secondary[100] }}
                    onClick={() => {
                      navigate(`/profile`);
                      setActive("");
                    }}
                  >
                    {currentUser.username}
                  </Typography>
                </Box>
                <LockOutlined
                  onClick={logOut}
                  sx={{
                    color: theme.palette.secondary[300],
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            ) : (
              <FlexBetween
                textTransform="none"
                gap="1rem"
                m="1.5rem 2rem 1rem 3rem"
              >
                <Box textAlign="left">
                  <Button
                    onClick={() => {
                      navigate("/login");
                      setActive("login");
                    }}
                    sx={{
                      color: theme.palette.secondary[100],
                      "&:hover": {
                        backgroundColor: theme.palette.primary[200],
                      },
                    }}
                  >
                    Login
                  </Button>
                </Box>
                <Box textAlign="left">
                  <Button
                    onClick={() => {
                      navigate("/register");
                      setActive("register");
                    }}
                    sx={{
                      color: theme.palette.secondary[100],
                      "&:hover": {
                        backgroundColor: theme.palette.primary[200],
                      },
                    }}
                  >
                    Register
                  </Button>
                </Box>
              </FlexBetween>
            )}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Navbar;
