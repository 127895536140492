import React from "react";
import { Box } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";
import ParameterCard from "../../../components/ParameterCard";

const HolterRecording = () => {
  const parameters = [
    {
      id: "qrsParam",
      name: "QRS",
      dataType: "Number",
      description: "Total number of QRSs recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "minBPMParam",
      name: "Min BPM",
      dataType: "Number",
      description:
        "Minimum beats per minute recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "maxBPMParam",
      name: "Max BPM",
      dataType: "Number",
      description:
        "Maximum beats per minute recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "meanBPMParam",
      name: "Mean BPM",
      dataType: "Number",
      description: "Mean beats per minute recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "bradyParam",
      name: "Bradycardias",
      dataType: "Number",
      description:
        "Total number of bradycardias recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "pausParam",
      name: "Pauses",
      dataType: "Number",
      description:
        "Total number of heart pauses recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "vpbParam",
      name: "VPB",
      dataType: "Number",
      description:
        "Total number of ventricular premature beats recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "vpbIsoParam",
      name: "Isolated VPB",
      dataType: "Number",
      description:
        "Total number of isolated ventricular premature beats recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "vpbCoupParam",
      name: "Cpuplet VPB",
      dataType: "Number",
      description:
        "Total number of couplet ventricular premature beats recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "vpbRunsParam",
      name: "VPB Runs",
      dataType: "Number",
      description:
        "Total number of ventricular premature beat runs recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "vpbBigParam",
      name: "VPB Bigeminies",
      dataType: "Number",
      description:
        "Total number of ventricular premature beat bigeminies recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "vpbTrigParam",
      name: "VPB Trigeminies",
      dataType: "Number",
      description:
        "Total number of ventricular premature beat trigeminies recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "vTachParam",
      name: "Ventricular Tachycardias",
      dataType: "Number",
      description:
        "Total number of ventricular tachycardias recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "svpbParam",
      name: "SVPB",
      dataType: "Number",
      description:
        "Total number of supra-ventricular premature beats recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "svpbIsoParam",
      name: "Isolated SVPB",
      dataType: "Number",
      description:
        "Total number of isolated supra-ventricular premature beats recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "svpbCoupParam",
      name: "Cpuplet SVPB",
      dataType: "Number",
      description:
        "Total number of couplet supra-ventricular premature beats recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "svpbRunsParam",
      name: "SVPB Runs",
      dataType: "Number",
      description:
        "Total number of supra-ventricular premature beat runs recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "svpbBigParam",
      name: "SVPB Bigeminies",
      dataType: "Number",
      description:
        "Total number of supra-ventricular premature beat bigeminies recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "svpbTrigParam",
      name: "SVPB Trigeminies",
      dataType: "Number",
      description:
        "Total number of supra-ventricular premature beat trigeminies recorded in the 24h Holter Recording",
      virtual: false,
    },
    {
      id: "rrInstParam",
      name: "RR Instabilities",
      dataType: "Number",
      description:
        "Total number of RR instabilities recorded in the 24h Holter Recording",
      virtual: false,
    },
  ];
  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Holter Recording"}
        subtitle={"Parameters recorded from the patient's 24h Holter Recording"}
      />
      <Box mt={"20px"}>
        {parameters.map((parameter, index) => {
          return <ParameterCard {...parameter} id={`ParameterCard${index}`} />;
        })}
      </Box>
    </Box>
  );
};

export default HolterRecording;
