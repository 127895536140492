import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import dotenv from 'dotenv';

// dotenv.config({ path: "../.." });

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND + "auth/" : process.env.REACT_APP_DEV_BACKEND + "auth/";

export const adminApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState }) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.accessToken) {
                headers.set("authorization", user.accessToken);
            }
            return headers;
        },
    }),
    reducerPath: "adminApi",
    tagTypes: ["Users"],
    endpoints: (build) => ({
        getAll: build.query({
            query: () => "getAll",
            providesTags: ["Users"],
        }),
    })
})

export const {
    useGetAllQuery,
} = adminApi;