import axios from "axios";
import authHeader from "./auth-header.js";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND + "mi/" : process.env.REACT_APP_DEV_BACKEND + "mi/";

function checkNumberAndPositive(value) {
    if (isNaN(value)) {
        return false;
    }
    if (value < 0) {
        return false;
    }
    return true;
}

export const addPatient = async (patient) => {
    if (!patient) {
        return "Patient can not be empty!";

    }

    if (!patient.firstName) {
        return "First Name can not be empty!";

    }

    if (!patient.lastName) {
        return "Last Name can not be empty!";

    }

    if (!patient.dateOfBirth) {
        return "Date of Birth can not be empty!";

    }

    if (!patient.gender) {
        return "Gender can not be empty!";

    }

    if (patient.gender !== "M" && patient.gender !== "F") {
        return "Gender not M or F!";

    }

    if (patient.infarctType === undefined) {
        return "Infarct Type can not be empty!";

    }

    if (patient.infarctType !== "STEMI" && patient.infarctType !== "NSTEMI") {
        return "Infarct Type must be STEMI or NSTEMI!";

    }

    if (patient.infarctArteries !== undefined) {
        if (isNaN(patient.infarctArteries)) {
            return "Infarct Arteries must be a number!";
        }
        if (!checkNumberAndPositive(patient.infarctArteries)) {
            return "Infarct Arteries must be greater than 0!";
        }
        if (patient.infarctArteries < 0) {
            return "Infarct Arteries must be greater than 0!";
        }
    }

    if (patient.infarctYear === undefined) {
        return "Infarct Year can not be empty!";

    }

    if (isNaN(patient.infarctYear)) {
        return "Infarct Year must be a number!";
    }

    if (!(patient.infarctYear > 1900)) {
        return "Infarct Year can not be before 1900!";
    }

    if (!(patient.infarctYear < new Date().getFullYear())) {
        return "Infarct Year can not be in the future!";
    }

    if (patient.infarctInferior === undefined) {
        return "Infarct Inferior can not be empty!";

    }

    if (typeof patient.infarctInferior != "boolean") {
        return "Infarct Inferior can not be anything but boolean!";

    }

    if (patient.infarctAnterior === undefined) {
        return "Infarct Anterior can not be empty!";

    }

    if (typeof patient.infarctAnterior != "boolean") {
        return "Infarct Anterior can not be anything but boolean!";

    }

    if (patient.infarctLateral === undefined) {
        return "Infarct Lateral can not be empty!";

    }

    if (typeof patient.infarctLateral != "boolean") {
        return "Infarct Lateral can not be anything but boolean!";

    }

    if (patient.infarctPosterior === undefined) {
        return "Infarct Posterior can not be empty!";

    }

    if (typeof patient.infarctPosterior != "boolean") {
        return "Infarct Posterior can not be anything but boolean!";

    }

    if (patient.cbcRBC) {
        if (!checkNumberAndPositive(patient.cbcRBC)) {
            return "RBC must be greater than or equal to 0!";

        }
    }

    if (patient.cbcNe) {
        if (!checkNumberAndPositive(patient.cbcNe)) {
            return "Ne must be greater than or equal to 0!";

        }
    }

    if (patient.cbcLy) {
        if (!checkNumberAndPositive(patient.cbcLy)) {
            return "Ly must be greater than or equal to 0!";

        }
    }

    if (patient.cbcMo) {
        if (!checkNumberAndPositive(patient.cbcMo)) {
            return "Mo must be greater than or equal to 0!";

        }
    }

    if (patient.cbcEos) {
        if (!checkNumberAndPositive(patient.cbcEos)) {
            return "Eos must be greater than or equal to 0!";

        }
    }

    if (patient.cbcHGB) {
        if (!checkNumberAndPositive(patient.cbcHGB)) {
            return "HGB must be greater than or equal to 0!";

        }
    }

    if (patient.cbcHCT) {
        if (!checkNumberAndPositive(patient.cbcHCT)) {
            return "HCT must be greater than or equal to 0!";

        }
        if (patient.cbcHCT < 0 || patient.cbcHCT > 100) {
            return "HCT must be between 0 and 100!";

        }
    }

    if (patient.cbcPLT) {
        if (!checkNumberAndPositive(patient.cbcPLT)) {
            return "PLT must be greater than or equal to 0!";

        }
    }

    if (patient.cbcWBC) {
        if (!checkNumberAndPositive(patient.cbcWBC)) {
            return "WBC must be greater than or equal to 0!";

        }
    }

    if (patient.bioAlbumin) {
        if (!checkNumberAndPositive(patient.bioAlbumin)) {
            return "Albumin must be greater than or equal to 0!";

        }
    }

    if (patient.bioBNP) {
        if (!checkNumberAndPositive(patient.bioBNP)) {
            return "BNP must be greater than or equal to 0!";

        }
    }

    if (patient.bioHBA1C) {
        if (!checkNumberAndPositive(patient.bioHBA1C)) {
            return "HBA1C must be greater than or equal to 0!";

        }
        if (patient.bioHBA1C < 0 || patient.bioHBA1C > 100) {
            return "HBA1C must be between 0 and 100!";

        }
    }

    if (patient.bioSGOT) {
        if (!checkNumberAndPositive(patient.bioSGOT)) {
            return "SGOT must be greater than or equal to 0!";

        }
    }

    if (patient.bioSGPT) {
        if (!checkNumberAndPositive(patient.bioSGPT)) {
            return "SGPT must be greater than or equal to 0!";

        }
    }

    if (patient.bioALP) {
        if (!checkNumberAndPositive(patient.bioALP)) {
            return "ALP must be greater than or equal to 0!";

        }
    }

    if (patient.bioGGT) {
        if (!checkNumberAndPositive(patient.bioGGT)) {
            return "GGT must be greater than or equal to 0!";

        }
    }

    if (patient.bioCHOL) {
        if (!checkNumberAndPositive(patient.bioCHOL)) {
            return "CHOL must be greater than or equal to 0!";

        }
    }

    if (patient.bioTRIG) {
        if (!checkNumberAndPositive(patient.bioTRIG)) {
            return "TRIG must be greater than or equal to 0!";

        }
    }

    if (patient.bioHDL) {
        if (!checkNumberAndPositive(patient.bioHDL)) {
            return "HDL must be greater than or equal to 0!";

        }
    }

    if (patient.bioUA) {
        if (!checkNumberAndPositive(patient.bioUA)) {
            return "UA must be greater than or equal to 0!";

        }
    }

    if (patient.bioCREA) {
        if (!checkNumberAndPositive(patient.bioCREA)) {
            return "CREA must be greater than or equal to 0!";

        }
    }

    const status = axios.post(API_URL + "add", {
        patient,
    }, { headers: authHeader() });

    return status;
};