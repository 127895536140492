import React from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";

const Results = () => {
  return (
    <Box width={"100%"} height={"100%"} m="1.5rem 2.5rem">
      <Header
        title={"Study Results"}
        subtitle={"Running statistics on the results of the study"}
      />
      <Box width={"100%"} height={"100%"} mt={"20px"}>
        <iframe
          width="60%"
          height="75%"
          title="Results"
          src="https://lookerstudio.google.com/embed/reporting/44cdc0ab-b3fc-4a4b-b494-ff6a3d106fd4/page/l71XD"
          allowfullscreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      </Box>
    </Box>
  );
};

export default Results;
