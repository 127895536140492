import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { useGetPatientsQuery } from "../../services/mi.result.service";
import DataGridCustomToolbar from "../../components/DataGridCustomToolbar";

const MITable = () => {
  const theme = useTheme();

  // values to be sent to backend
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [sort, setSort] = useState({});

  const { data, isLoading, error } = useGetPatientsQuery({
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    sort: JSON.stringify(sort),
  });

  const columns = [
    // { field: "fullName", headerName: "Name", width: 150 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 50,
    },
    // {
    //   field: "AMKA",
    //   headerName: "AMKA",
    //   type: "number",
    //   width: 110,
    // },
    {
      field: "infarctType",
      headerName: "Infarct Type",
      width: 90,
    },
    {
      field: "infarctAnterior",
      headerName: "Anterior",
      width: 70,
      valueGetter: (value, row) => (value.value === true ? "Yes" : "No"),
    },
    {
      field: "infarctInferior",
      headerName: "Inferior",
      width: 70,
      valueGetter: (value, row) => (value.value === true ? "Yes" : "No"),
    },
    {
      field: "infarctLateral",
      headerName: "Lateral",
      width: 70,
      valueGetter: (value, row) => (value.value === true ? "Yes" : "No"),
    },
    {
      field: "infarctPosterior",
      headerName: "Posterior",
      width: 70,
      valueGetter: (value, row) => (value.value === true ? "Yes" : "No"),
    },
    {
      field: "cbcRBC",
      headerName: ["RBC (10", <sup>12</sup>, "/L)"],
      type: "number",
      width: 85,
    },
    {
      field: "cbcHGB",
      headerName: "HGB (g/dL)",
      type: "number",
      width: 80,
    },
    {
      field: "cbcHCT",
      headerName: "HCT (%)",
      type: "number",
      width: 70,
    },
    {
      field: "cbcPLT",
      headerName: ["PLT (10", <sup>9</sup>, "/L)"],
      type: "number",
      width: 80,
    },
    {
      field: "cbcWBC",
      headerName: ["WBC (10", <sup>12</sup>, "/L)"],
      type: "number",
      width: 90,
    },
    {
      field: "bioSGOT",
      headerName: "SGOT (U/L)",
      type: "number",
      width: 75,
    },
    {
      field: "bioSGPT",
      headerName: "SGPT (U/L)",
      type: "number",
      width: 75,
    },
    {
      field: "bioALP",
      headerName: "ALP (U/L)",
      type: "number",
      width: 70,
    },
    {
      field: "bioGGT",
      headerName: "γGT (U/L)",
      type: "number",
      width: 70,
    },
    {
      field: "bioCHOL",
      headerName: "TC (mg/dL)",
      type: "number",
      width: 80,
    },
    {
      field: "bioTRIG",
      headerName: "TG (mg/dL)",
      type: "number",
      width: 80,
    },
    {
      field: "bioHDL",
      headerName: "HDL (mg/dL)",
      type: "number",
      width: 85,
    },
    {
      field: "bioLDL",
      headerName: "LDL (mg/dL)",
      type: "number",
      width: 85,
    },
    {
      field: "bioUA",
      headerName: "UA (mg/dL)",
      type: "number",
      width: 80,
    },
    {
      field: "bioCREA",
      headerName: "CREA (mg/dL)",
      type: "number",
      width: 95,
    },
    {
      field: "eGFR",
      headerName: "eGFR (mL/min/1.73m",
      type: "number",
      width: 150,
    },
    {
      field: "bioBNP",
      headerName: "BNP (pg/mL)",
      type: "number",
      width: 90,
    },
    {
      field: "bioHBA1C",
      headerName: "HBA1C (%)",
      type: "number",
      width: 90,
    },
    { field: "_id", headerName: "ID", width: 200 },
  ];

  //   console.log(data);

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="MI Patients"
        subtitle="Table of all the patients in the MI Project"
      />
      <Box
        mt={"40px"}
        height={"75vh"}
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.patients) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          pagination
          pageSizeOptions={[20, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
        />
      </Box>
    </Box>
  );
};

export default MITable;
