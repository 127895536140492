import React from "react";
import { useState, useEffect } from "react";
import { Box, useMediaQuery, IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";

const Layout = ({
  showModeratorBoard,
  showAdminBoard,
  showMIBoard,
  currentUser,
  logOut,
}) => {
  const isNonMobile = useMediaQuery("(min-width: 890px)");
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  useEffect(() => {
    if (!isNonMobile) {
      setIsNavbarOpen(false);
    }
  }, [isNonMobile]);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Navbar
        showAdminBoard={showAdminBoard}
        showMIBoard={showMIBoard}
        showModeratorBoard={showModeratorBoard}
        currentUser={currentUser}
        logOut={logOut}
        isNavbarOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
        drawerWidth="250px"
      />
      {!isNavbarOpen && (
        <IconButton
          onClick={() => setIsNavbarOpen(!isNavbarOpen)}
          sx={{ ml: 1 }}
          color="inherit"
        >
          <ChevronRight />
        </IconButton>
      )}
      <Box flexGrow={1}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
