import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";

export const ConfirmDialog = (props) => {
  const { title, content, open, setClosed, onConfirm, params } = props;
  return (
    <Dialog
      open={open}
      onClose={setClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={setClosed}>Disagree</Button>
        <Button onClick={onConfirm(params)} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>

    // <Dialog open={open} onClose={() => setOpen(false)}>
    //   <DialogTitle>{title}</DialogTitle>
    //   <DialogContent>{children}</DialogContent>
    //   <DialogActions>
    //     <Button
    //       onClick={() => setOpen(false)}
    //       color={theme.palette.secondary[100]}
    //     >
    //       No
    //     </Button>
    //     <Button
    //       onClick={() => {
    //         setOpen(false);
    //         onConfirm(params);
    //       }}
    //       color={theme.palette.secondary[300]}
    //     >
    //       Yes
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
};
