import React from "react";
import { Box, Link, useTheme } from "@mui/material";
import Header from "../../components/Header";

const CardioParameters = () => {
  const theme = useTheme();
  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={"Parameter Categories"}
        subtitle={"Open each category to explore its nested parameters"}
      />
      <Box mt={"20px"}>
        <Link variant="h2" color={theme.palette.neutral[100]} href="/basicData">
          Basic Data
        </Link>
        <br />
        <Link
          variant="h2"
          color={theme.palette.neutral[100]}
          href="/bodyCondition"
        >
          Body Condition
        </Link>
        <br />
        <Link
          variant="h2"
          color={theme.palette.neutral[100]}
          href="/medications"
        >
          Medications
        </Link>
        <br />
        <Link
          variant="h2"
          color={theme.palette.neutral[100]}
          href="/electrophysiologicalStudy"
        >
          Electrophysiological Study
        </Link>
        <br />
        <Link
          variant="h2"
          color={theme.palette.neutral[100]}
          href="/coronaryAngiography"
        >
          Coronary Angiography
        </Link>
        <br />
        <Link
          variant="h2"
          color={theme.palette.neutral[100]}
          href="/transthoracicEchocardiography"
        >
          Transthoracic Echocardiography
        </Link>
        <br />
        <Link
          variant="h2"
          color={theme.palette.neutral[100]}
          href="/heartScintigraphy"
        >
          Heart Scintigraphy
        </Link>
        <br />
        <Link
          variant="h2"
          color={theme.palette.neutral[100]}
          href="/holterRecording"
        >
          Holter Recording
        </Link>
        <br />
      </Box>
    </Box>
  );
};

export default CardioParameters;
