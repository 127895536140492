import React from "react";
import { Box } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";
import ParameterCard from "../../../components/ParameterCard";

const TransthoracicEchocardiography = () => {
  const parameters = [
    {
      id: "arParam",
      name: "AR",
      dataType: "Number",
      description: "Aortic Root diameter (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "laParam",
      name: "LA",
      dataType: "Number",
      description: "Left Atrium diameter (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "rvParam",
      name: "RV",
      dataType: "Number",
      description: "Right Ventricle diameter (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "ivsParam",
      name: "IVS",
      dataType: "Number",
      description:
        "Intraventricular Septum thickness (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "pwParam",
      name: "PW",
      dataType: "Number",
      description: "Posterior Wall thickness (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "lveddParam",
      name: "LVEDD",
      dataType: "Number",
      description:
        "Left Ventricle End Diastolic Diameter (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "lvesdParam",
      name: "LVESD",
      dataType: "Number",
      description:
        "Left Ventricle End Systolic Diameter (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "lvcfParam",
      name: "LVCF",
      dataType: "Number",
      description:
        "Left Ventricle Contraction Fraction (%) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "lvefParam",
      name: "LVEF",
      dataType: "Number",
      description:
        "Left Ventricle Ejection Fraction (%) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "aaParam",
      name: "AA",
      dataType: "Number",
      description: "Ascending Aorta diameter (mm) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "a2lParam",
      name: "A2 Leaflets",
      dataType: "Number",
      description: "Aortic Valve leaflets count as recorded in the TTE",
      virtual: false,
    },
    {
      id: "a2dParam",
      name: "A2 Degeneration",
      dataType: "Number",
      description:
        "Aortic Valve degeneration (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "a2rParam",
      name: "A2 Range",
      dataType: "Number",
      description:
        "Aortic Valve range (0:Severely Affected, 1:Moderately Affected, 2:Mildly Affected, 3:Intact) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "mvdParam",
      name: "MV Degeneration",
      dataType: "Number",
      description:
        "Mitral Valve degeneration (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "mvrParam",
      name: "MV Range",
      dataType: "Number",
      description:
        "Mitral Valve range (0:Severely Affected, 1:Moderately Affected, 2:Mildly Affected, 3:Intact) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "lvdisParam",
      name: "LV Distention",
      dataType: "Number",
      description:
        "Left Ventricle distension (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "lvhypParam",
      name: "LV Hypertrophy",
      dataType: "Boolean",
      description: "Whether Left Ventricle hypertrophy was recorded in the TTE",
      virtual: false,
    },
    {
      id: "lvdysParam",
      name: "LV Dysfunction",
      dataType: "Number",
      description:
        "Left Ventricle dysfunction (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "apicalParam",
      name: "Apical Movement",
      dataType: "Number",
      description:
        "Apical wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "apicalLateralParam",
      name: "Apical Lateral Movement",
      dataType: "Number",
      description:
        "Apical Lateral wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "apicalSeptumParam",
      name: "Apical Septum Movement",
      dataType: "Number",
      description:
        "Apical Septum wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "midAnterolateralParam",
      name: "Mid Anterolateral Movement",
      dataType: "Number",
      description:
        "Mid Anterolateral wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "midInferoseptumParam",
      name: "Mid Inferoseptum Movement",
      dataType: "Number",
      description:
        "Mid Inferoseptum wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "basalAnterolateralParam",
      name: "Basal Anterolateral Movement",
      dataType: "Number",
      description:
        "Basal Anterolateral wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "basalInferoseptumParam",
      name: "Basal Inferoseptum Movement",
      dataType: "Number",
      description:
        "Basal Inferoseptum wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "apicalInferiorParam",
      name: "Apical Inferior Movement",
      dataType: "Number",
      description:
        "Apical Inferior wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "midInferiorParam",
      name: "Mid Inferior Movement",
      dataType: "Number",
      description:
        "Mid Inferior wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "basalInferiorParam",
      name: "Basal Inferior Movement",
      dataType: "Number",
      description:
        "Basal Inferior wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "apicalAnteriorParam",
      name: "Apical Anterior Movement",
      dataType: "Number",
      description:
        "Apical Anterior wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "midAnteriorParam",
      name: "Mid Anterior Movement",
      dataType: "Number",
      description:
        "Mid Anterior wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "basalAnteriorParam",
      name: "Basal Anterior Movement",
      dataType: "Number",
      description:
        "Basal Anterior wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "midInferolateralParam",
      name: "Mid Inferolateral Movement",
      dataType: "Number",
      description:
        "Mid Inferolateral wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "midAnteroseptumParam",
      name: "Mid Anteroseptum Movement",
      dataType: "Number",
      description:
        "Mid Anteroseptum wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "basalInferolateralParam",
      name: "Basal Inferolateral Movement",
      dataType: "Number",
      description:
        "Basal Inferolateral wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "basalAnteroseptumParam",
      name: "Basal Anteroseptum Movement",
      dataType: "Number",
      description:
        "Basal Anteroseptum wall movement recorded in the TTE (0:Akinesia, 1:Hypokinesia/Dyskinesia, 2:Normal)",
      virtual: false,
    },
    {
      id: "ladisParam",
      name: "LA Distention",
      dataType: "Number",
      description:
        "Left Atrium distension (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "radisParam",
      name: "RA Distention",
      dataType: "Number",
      description:
        "Right Atrium distension (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "rvdisParam",
      name: "RV Distention",
      dataType: "Number",
      description:
        "Right Ventricle distension (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE",
      virtual: false,
    },
    {
      id: "a2DopplerParam",
      name: "A2 Regurgitation",
      dataType: "Number",
      description:
        "Aortic Valve regurgitation (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE Doppler",
      virtual: false,
    },
    {
      id: "p2DopplerParam",
      name: "P2 Regurgitation",
      dataType: "Number",
      description:
        "Pulmonary Valve regurgitation (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE Doppler",
      virtual: false,
    },
    {
      id: "mvDopplerParam",
      name: "MV Regurgitation",
      dataType: "Number",
      description:
        "Mitral Valve regurgitation (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE Doppler",
      virtual: false,
    },
    {
      id: "tvDopplerParam",
      name: "TV Regurgitation",
      dataType: "Number",
      description:
        "Tricuspid Valve regurgitation (0:None, 1:Mild, 2:Moderate, 3:Severe) as recorded in the TTE Doppler",
      virtual: false,
    },
    {
      id: "pericardiumParam",
      name: "Pericardial Effusion",
      dataType: "Boolean",
      description: "Whether pericardial effusion was recorded in the TTE",
      virtual: false,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Transthoracic Echocardiography"}
        subtitle={
          "Parameters recorded from the patient's Transthoracic Echocardiography"
        }
      />
      <Box mt={"20px"}>
        {parameters.map((parameter, index) => {
          return <ParameterCard {...parameter} id={`ParameterCard${index}`} />;
        })}
      </Box>
    </Box>
  );
};

export default TransthoracicEchocardiography;
