import React from "react";
import {
  Box,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const AboutWithSubsCard = ({ title, list }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="h2" color={theme.palette.secondary[200]}>
        {title}
      </Typography>
      {Object.keys(list).map((key, index) => {
        return (
          <>
            <Typography variant="h3" color={theme.palette.secondary[200]}>
              {key}
            </Typography>
            <List>
              {list[key].map((item) => (
                <ListItem
                  key={`${title}:${key}:${index}:${item}`}
                  disablePadding
                >
                  <ListItemText
                    disableTypography
                    primary={<Typography variant="h4">{item}</Typography>}
                  />
                </ListItem>
              ))}
            </List>
          </>
        );
      })}
    </Box>
  );
};

export default AboutWithSubsCard;
