import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import MIDemographicStatistics from "../../components/MIDemographicStatistics";
import MIBloodStatistics from "../../components/MIBloodStatistics";
// import { mockData } from "./mock";
import MIMetabolicStatistics from "../../components/MIMetabolicStatistics";
import MILiverKidneyStatistics from "../../components/MILiverKidneyStatistics";
import MIInflammationStatistics from "../../components/MIInflammationStatistics";
import { useGetStatsQuery } from "../../services/mi.result.service";

const MIStats = () => {
  const theme = useTheme();
  // const emptyStats = mockData();
  const [step, setStep] = useState(1);
  const { data, isLoading, error } = useGetStatsQuery();

  const renderStep = (stepToRender) => {
    switch (stepToRender) {
      case 1:
        return <MIDemographicStatistics stats={data.stats} />;
      case 2:
        return <MIBloodStatistics stats={data.stats} />;
      case 3:
        return <MIMetabolicStatistics stats={data.stats} />;
      case 4:
        return <MILiverKidneyStatistics stats={data.stats} />;
      case 5:
        return <MIInflammationStatistics stats={data.stats} />;
      default:
        return <Typography variant="h4">Something went wrong</Typography>;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeStep = (event, newValue) => {
    setStep(newValue + 1);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={"MI Result Statistics"}
        subtitle={
          "Live statistics of the MI project results. Unconfirmed data."
        }
      />
      {isLoading ? (
        <></>
      ) : (
        <Box
          mt={"40px"}
          height="35vh"
          width={"100%"}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            gridTemplateRows: "0.5fr repeat(8, 0.5fr)",
            gridColumnGap: "5px",
            gridRowGap: "5px",
          }}
        >
          <Box
            sx={{
              borderColor: "divider",
              gridArea: "1 / 1 / 2 / 7",
            }}
          >
            <Tabs
              value={step - 1}
              onChange={handleChangeStep}
              aria-label="basic tabs example"
              sx={{
                "& button.Mui-selected": {
                  color: theme.palette.secondary[300],
                },
              }}
            >
              <Tab label="Infarct Statistics" {...a11yProps(0)} />
              <Tab label="Blood Statistics" {...a11yProps(1)} />
              <Tab label="Metabolic Statistics" {...a11yProps(2)} />
              <Tab label="Liver & Kidney Statistics" {...a11yProps(3)} />
              <Tab label="Inflammation Statistics" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <Box sx={{ gridArea: "2 / 1 / 10 / 7" }}>{renderStep(step)}</Box>
        </Box>
      )}
    </Box>
  );
};

export default MIStats;
