import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "dark",
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === 'light' ? "dark" : "light";
        }
    }
})

const { reducer, actions } = globalSlice;
export const { setMode } = actions;
export default reducer;