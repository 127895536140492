import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import * as d3 from "d3";
import {
  histogramChart,
  histogramDoubleChart,
  densityChart,
  densityDoubleChart,
} from "../tools/plots";

const MIBloodStatistics = ({ stats }) => {
  const theme = useTheme();
  const histoRBCRef = useRef();
  const histoHGBRef = useRef();
  const histoHCTRef = useRef();
  const histoPLTRef = useRef();
  const histoUARef = useRef();
  const histoBNPRef = useRef();
  const histoPLTTooltipRef = useRef();
  const histoUATooltipRef = useRef();
  const histoBNPTooltipRef = useRef();
  const [strokeColor, setStrokeColor] = useState("white");
  const width = 600;
  const height = 400;

  useEffect(() => {
    setStrokeColor(theme.palette.text.primary);
  }, [theme]);

  useEffect(() => {
    const dataM = stats.mRBCs;
    const dataF = stats.fRBCs;
    const svg = d3
      .select(histoRBCRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible")
      .append("g");

    // histogramDoubleChart({
    //   data1: dataM,
    //   data2: dataF,
    //   svg: svg,
    //   width: width,
    //   height: height,
    //   binNo: 10,
    //   xTitle: "RBC (·10^12/L)",
    //   title1: "Males",
    //   title2: "Females",
    //   strokeColor: strokeColor,
    // });

    densityDoubleChart({
      data1: dataM,
      data2: dataF,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "RBC (·10^12/L)",
      title1: "Males",
      title2: "Females",
      strokeColor: strokeColor,
      kernelWidth: 0.5,
      customMax: 7,
      customMin: 1.5,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const dataM = stats.mHGBs;
    const dataF = stats.fHGBs;
    const svg = d3
      .select(histoHGBRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible")
      .append("g");

    // histogramDoubleChart({
    //   data1: dataM,
    //   data2: dataF,
    //   svg: svg,
    //   width: width,
    //   height: height,
    //   binNo: 10,
    //   xTitle: "HGB (mg/dL)",
    //   title1: "Males",
    //   title2: "Females",
    //   strokeColor: strokeColor,
    // });

    densityDoubleChart({
      data1: dataM,
      data2: dataF,
      svg: svg,
      width: width,
      height: height,
      binNo: 10,
      xTitle: "HGB (mg/dL)",
      title1: "Males",
      title2: "Females",
      strokeColor: strokeColor,
      kernelWidth: 1,
      customMax: 21,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const dataM = stats.mHCTs;
    const dataF = stats.fHCTs;
    const svg = d3
      .select(histoHCTRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible")
      .append("g");

    // histogramDoubleChart({
    //   data1: dataM,
    //   data2: dataF,
    //   svg: svg,
    //   width: width,
    //   height: height,
    //   binNo: 5,
    //   xTitle: "HCT (%)",
    //   title1: "Males",
    //   title2: "Females",
    //   strokeColor: strokeColor,
    // });

    densityDoubleChart({
      data1: dataM,
      data2: dataF,
      svg: svg,
      width: width,
      height: height,
      binNo: 20,
      xTitle: "HCT (%)",
      title1: "Males",
      title2: "Females",
      strokeColor: strokeColor,
      kernelWidth: 5,
      customMin: 15,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.PLTs;
    const svg = d3
      .select(histoPLTRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoPLTTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "PLT (·10^9/L)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "PLT (·10^9/L)",
      strokeColor: strokeColor,
      kernelWidth: 10,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.UAs;
    const svg = d3
      .select(histoUARef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoUATooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "UA (mg/dL)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "UA (mg/dL)",
      strokeColor: strokeColor,
      kernelWidth: 0.5,
      customMax: 14,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.BNPs;
    const svg = d3
      .select(histoBNPRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoBNPTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "BNP (pg/mL)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 40,
      xTitle: "BNP (pg/mL)",
      strokeColor: strokeColor,
      customMax: 3500,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <svg ref={histoRBCRef}></svg>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <svg ref={histoHGBRef}></svg>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <svg ref={histoHCTRef}></svg>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoPLTRef}></svg>
        </Box>
        <Box ref={histoPLTTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoUARef}></svg>
        </Box>
        <Box ref={histoUATooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoBNPRef}></svg>
        </Box>
        <Box ref={histoBNPTooltipRef}></Box>
      </Box>
    </Box>
  );
};

export default MIBloodStatistics;
