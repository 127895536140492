import axios from "axios";
import EventBus from '../common/EventBus.js';
import authHeader from "./auth-header";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND + "user/" : process.env.REACT_APP_DEV_BACKEND + "user/";

const getPublicContent = () => {
    return axios.get(API_URL + "all");
};

const getAboutContent = () => {
    return axios.get(API_URL + "about");
};

const getUserBoard = () => {
    const userBoard = axios.get(API_URL + "user", { headers: authHeader() }).catch(error => {
        if (error.response.status) {
            if (error.response.status === 403 || error.response.status === 401) {
                EventBus.dispatch("logout")
            }
        }
    });
    return userBoard
};

const getModeratorBoard = () => {
    const moderatorBoard = axios.get(API_URL + "mod", { headers: authHeader() }).catch(error => {
        if (error.response.status) {
            if (error.response.status === 403 || error.response.status === 401) {
                EventBus.dispatch("logout")
            }
        }
    });
    return moderatorBoard;
};

const getAdminBoard = () => {
    const adminBoard = axios.get(API_URL + "admin", { headers: authHeader() }).catch(error => {
        if (error.response.status) {
            if (error.response.status === 403 || error.response.status === 401) {
                EventBus.dispatch("logout")
            }
        }
    });
    return adminBoard;
};

const userService = {
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    getAboutContent
};

export default userService