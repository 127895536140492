import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { update } from "../../redux/auth";
import { clearMessage, setMessage } from "../../redux/message";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box, Button, TextField } from "@mui/material";
import Header from "../../components/Header.jsx";
import { useState, useEffect } from "react";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleUpdate = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const email = data.get("email");
    const password = data.get("password");
    const repeatPassword = data.get("repeatPassword");
    const oldPassword = data.get("oldPassword");
    if (!username) {
      setSuccessful(false);
      dispatch(setMessage("Username is required"));
      return;
    }
    if (!email) {
      setSuccessful(false);
      dispatch(setMessage("Email is required"));
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setSuccessful(false);
      dispatch(setMessage("Email is wrong"));
      return;
    }
    if (username.length < 3 || username.length > 20) {
      setSuccessful(false);
      dispatch(setMessage("Username must be between 3 and 20 characters long"));
      return;
    }
    if (password && (password.length < 6 || password.length > 40)) {
      setSuccessful(false);
      dispatch(setMessage("Password must be between 6 and 40 characters long"));
      return;
    }
    if (password && password !== repeatPassword) {
      setSuccessful(false);
      dispatch(setMessage("Passwords don't match"));
      return;
    }
    if (password && !oldPassword) {
      setSuccessful(false);
      dispatch(setMessage("Please provide old password, to change it"));
      return;
    }
    if (
      password &&
      oldPassword &&
      (oldPassword.length < 6 || oldPassword.length > 40)
    ) {
      setSuccessful(false);
      dispatch(
        setMessage("Old password must be between 6 and 40 characters long")
      );
      return;
    }
    setLoading(true);
    dispatch(update({ username, password, email, oldPassword }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
        navigate("/profile");
        window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
        setLoading(false);
      });
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const stringAuth = currentUser.roles.join(", ").replaceAll("ROLE_", "");

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={`${currentUser.username}'s Profile`}
        subtitle="Edit your profile"
      />
      <Box
        sx={{
          marginTop: "1.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>Id: {currentUser.id}</Typography>
        <Typography>Authorities: {stringAuth}</Typography>
        <Box
          component="form"
          onSubmit={handleUpdate}
          noValidate
          sx={{ mt: 1 }}
          maxWidth={"50%"}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            defaultValue={currentUser.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            defaultValue={currentUser.username}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="oldPassword"
            label="Old Password"
            type="password"
            id="oldPassword"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="repeatPassword"
            label="Repeat Password"
            type="password"
            id="repeatPassword"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Update
          </Button>
        </Box>
      </Box>
      {message && (
        <Typography variant="h6" color={successful ? "green" : "red"}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Profile;
