import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import * as d3 from "d3";
import { histogramChart, densityChart } from "../tools/plots";

const MIInflammationStatistics = ({ stats }) => {
  const theme = useTheme();
  const histoNLRRef = useRef();
  const histoMLRRef = useRef();
  const histoPLRRef = useRef();
  const histoSIIRef = useRef();
  const histoSIRIRef = useRef();
  const histoAISIRef = useRef();
  const histoNLRTooltipRef = useRef();
  const histoMLRTooltipRef = useRef();
  const histoPLRTooltipRef = useRef();
  const histoSIITooltipRef = useRef();
  const histoSIRITooltipRef = useRef();
  const histoAISITooltipRef = useRef();
  const [strokeColor, setStrokeColor] = useState("white");
  const width = 600;
  const height = 400;

  useEffect(() => {
    setStrokeColor(theme.palette.text.primary);
  }, [theme]);

  useEffect(() => {
    const data = stats.NLRs;
    const svg = d3
      .select(histoNLRRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoNLRTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "NLR",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 25,
      xTitle: "NLR",
      strokeColor: strokeColor,
      customMax: 100,
      kernelWidth: 1,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.MLRs;
    const svg = d3
      .select(histoMLRRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoMLRTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "MLR",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "MLR",
      strokeColor: strokeColor,
      customMax: 5,
      kernelWidth: 0.1,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.PLRs;
    const svg = d3
      .select(histoPLRRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoPLRTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "PLR",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "PLR",
      strokeColor: strokeColor,
      customMax: 400,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.SIIs;
    const svg = d3
      .select(histoSIIRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoSIITooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "SII",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 25,
      xTitle: "SII",
      strokeColor: strokeColor,
      kernelWidth: 100,
      customMax: 8000,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.SIRIs;
    const svg = d3
      .select(histoSIRIRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoSIRITooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "SIRI",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 25,
      xTitle: "SIRI",
      strokeColor: strokeColor,
      kernelWidth: 0.1,
      customMax: 15,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.AISIs;
    const svg = d3
      .select(histoAISIRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoAISITooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "AISI",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "AISI",
      strokeColor: strokeColor,
      kernelWidth: 15,
      customMax: 6000,
      customMin: 1000,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoNLRRef}></svg>
        </Box>
        <Box ref={histoNLRTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoMLRRef}></svg>
        </Box>
        <Box ref={histoMLRTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoPLRRef}></svg>
        </Box>
        <Box ref={histoPLRTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoSIIRef}></svg>
        </Box>
        <Box ref={histoSIITooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoSIRIRef}></svg>
        </Box>
        <Box ref={histoSIRITooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoAISIRef}></svg>
        </Box>
        <Box ref={histoAISITooltipRef}></Box>
      </Box>
    </Box>
  );
};

export default MIInflammationStatistics;
