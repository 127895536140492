import React from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const MIInfarctForm = ({ patient, handleChange, handleCheckChange }) => {
  return (
    <Box sx={{ mt: "20px" }}>
      <Box>
        <TextField
          label="Year"
          placeholder="Year"
          type="number"
          value={patient.infarctYear}
          onChange={handleChange("infarctYear")}
          sx={{ width: "100px" }}
        />
        <FormControl sx={{ minWidth: "125px", ml: "20px" }}>
          <InputLabel id="demo-simple-select-label">Infarct Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={patient.infarctType}
            label="Infarct Type"
            onChange={handleChange("infarctType")}
          >
            <MenuItem value={"STEMI"}>STEMI</MenuItem>
            <MenuItem value={"NSTEMI"}>NSTEMI</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Number of Arteries"
          placeholder="Number of Arteries"
          type="number"
          value={patient.infarctArteries}
          onChange={handleChange("infarctArteries")}
          sx={{ width: "100px", ml: "20px" }}
        />
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={patient.infarctAnterior}
              onChange={handleCheckChange("infarctAnterior")}
            />
          }
          label="Anterior Involvement"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={patient.infarctInferior}
              onChange={handleCheckChange("infarctInferior")}
            />
          }
          label="Inferior Involvement"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={patient.infarctPosterior}
              onChange={handleCheckChange("infarctPosterior")}
            />
          }
          label="Posterior Involvement"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={patient.infarctLateral}
              onChange={handleCheckChange("infarctLateral")}
            />
          }
          label="Lateral Involvement"
        />
      </FormGroup>
    </Box>
  );
};

export default MIInfarctForm;
