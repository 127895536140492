import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header.jsx";
import { useGetAllQuery } from "../../services/admin.service.js";
import { ConfirmDialog } from "../../components/ConfirmDialog.jsx";

const BoardAdmin = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetAllQuery();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toAdmin, setToAdmin] = useState({});
  const makeAdmin = (params) => {
    setConfirmOpen(false);
    console.log(params);
  };
  const handleDialogClose = () => {
    setConfirmOpen(false);
  };
  const confirmAdmin = (params) => {
    setToAdmin(params);
    setConfirmOpen(true);
  };
  const columns = [
    {
      field: "username",
      headerName: "Username",
      flex: 0.8,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
    },
    {
      field: "roles",
      headerName: "Authorities",
      flex: 1,
      renderCell: (params) =>
        params.value
          .map(function (item) {
            return item["name"];
          })
          .join(", "),
    },
  ];

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header
          title="Admin Management"
          subtitle="Double click row to promote to admin"
        />
        <Box
          mt={"40px"}
          height={"75vh"}
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={data || []}
            columns={columns}
            onRowDoubleClick={(params) => confirmAdmin(params)}
          />
        </Box>
      </Box>
      <ConfirmDialog
        title="Make User Admin?"
        content="Are you sure you want to upgrade this user?"
        open={confirmOpen}
        setClosed={handleDialogClose}
        onConfirm={makeAdmin}
        params={toAdmin}
      ></ConfirmDialog>
    </>
  );
};

export default BoardAdmin;
