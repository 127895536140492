import React from "react";
import { Box } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";
import ParameterCard from "../../../components/ParameterCard";

const ElectrophysiologicalStudy = () => {
  const parameters = [
    {
      id: "snrtParam",
      name: "SNRT",
      dataType: "Number",
      description: "Patient's SNRT as recorded in the EPS",
      virtual: false,
    },
    {
      id: "snrtcParam",
      name: "SNRTc",
      dataType: "Number",
      description: "Patient's SNRTc as recorded in the EPS",
      virtual: false,
    },
    {
      id: "ahParam",
      name: "AH",
      dataType: "Number",
      description: "Patient's AH as recorded in the EPS",
      virtual: false,
    },
    {
      id: "ahAtroParam",
      name: "AH after Atropine",
      dataType: "Number",
      description:
        "Patient's AH after atropine administration as recorded in the EPS",
      virtual: false,
    },
    {
      id: "weckenbachParam",
      name: "Weckenbach",
      dataType: "Number",
      description: "Patient's Weckenbach sign as recorded in the EPS",
      virtual: false,
    },
    {
      id: "weckenbachAtroParam",
      name: "Weckenbach after Atropine",
      dataType: "Number",
      description:
        "Patient's Weckenbach sign after atropine administration as recorded in the EPS",
      virtual: false,
    },
    {
      id: "weckenbachIsoParam",
      name: "Weckenbach after Isoproterenol",
      dataType: "Number",
      description:
        "Patient's Weckenbach sign after isoproterenol administration as recorded in the EPS",
      virtual: false,
    },
    {
      id: "hvParam",
      name: "HV",
      dataType: "Number",
      description: "Patient's HV as recorded in the EPS",
      virtual: false,
    },
    {
      id: "aarParam",
      name: "Atrial Arrhythmias",
      dataType: "AtrialArrhythmia",
      description: "Patient's atrial arrhythmias recorded in the EPS",
      virtual: false,
    },
    {
      id: "varParam",
      name: "Ventricular Arrhythmias",
      dataType: "VentricularArrhythmia",
      description: "Patient's ventricular arrhythmias recorded in the EPS",
      virtual: false,
    },
    {
      id: "arStopParam",
      name: "Arrhythmia Stop",
      dataType: "String",
      description:
        "Method that stoped the arrhythmias recorded in the EPS (e.g. DCC)",
      virtual: false,
    },
    {
      id: "carSinHypersParam",
      name: "Carotid Sinus Hypersensitivity",
      dataType: "Boolean",
      description:
        "Whether carotid sinus hypersensitivity was recorded in the EPS",
      virtual: false,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Electrophysiological Study"}
        subtitle={
          "Parameters recorded from the patient's Electrophysiological Study"
        }
      />
      <Box mt={"20px"}>
        {parameters.map((parameter, index) => {
          return <ParameterCard {...parameter} id={`ParameterCard${index}`} />;
        })}
      </Box>
    </Box>
  );
};

export default ElectrophysiologicalStudy;
