import { Typography, Box, useTheme, IconButton } from "@mui/material";
import React from "react";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ParameterHeader = ({ title, subtitle }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        flexGrow: 1,
      }}
    >
      <IconButton
        color="inherit"
        onClick={() => {
          navigate("/cardioParameters");
        }}
      >
        <ChevronLeft />
      </IconButton>
      <Box ml={"1.5rem"}>
        <Typography
          variant="h2"
          color={theme.palette.secondary[300]}
          fontWeight="bold"
          sx={{ mb: "5px" }}
        >
          {title}
        </Typography>
        <Typography variant="h5" color={theme.palette.secondary[100]}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default ParameterHeader;
