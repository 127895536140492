import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import * as d3 from "d3";
import { histogramChart, densityChart } from "../tools/plots";

const MIMetabolicStatistics = ({ stats }) => {
  const theme = useTheme();
  const histoCHOLRef = useRef();
  const histoTRIGRef = useRef();
  const histoLDLRef = useRef();
  const histoHDLRef = useRef();
  const histoRCRef = useRef();
  const histoHBA1CRef = useRef();
  const histoCHOLTooltipRef = useRef();
  const histoTRIGTooltipRef = useRef();
  const histoLDLTooltipRef = useRef();
  const histoHDLTooltipRef = useRef();
  const histoRCTooltipRef = useRef();
  const histoHBA1CTooltipRef = useRef();
  const [strokeColor, setStrokeColor] = useState("white");
  const width = 600;
  const height = 400;

  useEffect(() => {
    setStrokeColor(theme.palette.text.primary);
  }, [theme]);

  useEffect(() => {
    const data = stats.CHOLs;
    const svg = d3
      .select(histoCHOLRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoCHOLTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "TC (mg/dL)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "TC (mg/dL)",
      strokeColor: strokeColor,
      customMax: 350,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.TRIGs;
    const svg = d3
      .select(histoTRIGRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoTRIGTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "TG (mg/dL)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 20,
      xTitle: "TG (mg/dL)",
      strokeColor: strokeColor,
      customMax: 380,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.LDLs;
    const svg = d3
      .select(histoLDLRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoLDLTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "LDL (mg/dL)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "LDL (mg/dL)",
      strokeColor: strokeColor,
      customMax: 240,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.HDLs;
    const svg = d3
      .select(histoHDLRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoHDLTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "HDL (mg/dL)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 10,
      xTitle: "HDL (mg/dL)",
      strokeColor: strokeColor,
      customMax: 120,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.RCs;
    const svg = d3
      .select(histoRCRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoRCTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "RC (mg/dL)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 10,
      xTitle: "RC (mg/dL)",
      strokeColor: strokeColor,
      customMax: 120,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.HBA1Cs;
    const svg = d3
      .select(histoHBA1CRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoHBA1CTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    histogramChart({
      data: data,
      svg: svg,
      tooltip: tooltip,
      totalPatients: stats.totalPatients,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "HBA1C (%)",
      strokeColor: strokeColor,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoCHOLRef}></svg>
        </Box>
        <Box ref={histoCHOLTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoTRIGRef}></svg>
        </Box>
        <Box ref={histoTRIGTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoLDLRef}></svg>
        </Box>
        <Box ref={histoLDLTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoHDLRef}></svg>
        </Box>
        <Box ref={histoHDLTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoRCRef}></svg>
        </Box>
        <Box ref={histoRCTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoHBA1CRef}></svg>
        </Box>
        <Box ref={histoHBA1CTooltipRef}></Box>
      </Box>
    </Box>
  );
};

export default MIMetabolicStatistics;
