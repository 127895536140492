import React from "react";
import ReviewField from "./ReviewField";
import { Typography, useTheme } from "@mui/material";

const ReviewFieldUnits = ({ title, content, units, ml }) => {
  const theme = useTheme();
  return (
    <>
      <ReviewField title={title} content={content} ml={ml} />
      <Typography variant="h5" color={theme.palette.secondary[100]} ml={"2px"}>
        {units}
      </Typography>
    </>
  );
};

export default ReviewFieldUnits;
