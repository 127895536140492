import React from "react";
import { Box, Typography, List, ListItemText, useTheme } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";

const Medications = () => {
  const theme = useTheme();
  const rxParams = [
    "Commercial Name (e.g. Plavix)",
    "Substance Name (e.g. Clopidogrel)",
    "ATC code (e.g. B01AC04)",
    "Dosage (e.g. 75mg)",
    "Administration (e.g. P.O.)",
    "Frequency (e.g. 1x1)",
  ];
  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Medications"}
        subtitle={
          "Parameters recorded about the medications the patient receives"
        }
      />
      <Box mt={"20px"}>
        <Typography variant="h3" color={theme.palette.secondary[300]}>
          For all the medications the patient is under, we record the following
          information:
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 4 }}>
          {rxParams.map((parameter, index) => {
            return (
              <ListItemText
                sx={{ display: "list-item" }}
                primaryTypographyProps={{
                  fontSize: "22px",
                  color: theme.palette.secondary[100],
                }}
                primary={parameter}
                id={`drugParameter${index}`}
              />
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default Medications;
