import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import * as d3 from "d3";
import {
  pieChart,
  histogramChart,
  barChart,
  densityChart,
} from "../tools/plots";

const MIDemographicStatistics = ({ stats }) => {
  const theme = useTheme();
  const histoAgeRef = useRef();
  const histoAgeTooltipRef = useRef();
  const pieGenderRef = useRef();
  const pieInfarctRef = useRef();
  const barWallRef = useRef();
  const barArteriesRef = useRef();
  const [strokeColor, setStrokeColor] = useState("white");
  const width = 600;
  const height = 400;

  useEffect(() => {
    setStrokeColor(theme.palette.text.primary);
  }, [theme]);

  useEffect(() => {
    const data = stats.ages;
    console.log(data);
    const svg = d3
      .select(histoAgeRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoAgeTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "Age at Infarct",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "Age at Infarct",
      strokeColor: strokeColor,
      customMax: 120,
      kernelWidth: 5,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    // setup svg
    const data = stats.genders;
    const radius = Math.min(width, height) / 2;
    const svg = d3
      .select(pieGenderRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    pieChart({
      data: data,
      svg: svg,
      radius: radius,
      strokeColor: strokeColor,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    // setup svg
    const data = stats.infarcts;
    const radius = Math.min(width, height) / 2;
    const svg = d3
      .select(pieInfarctRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    pieChart({
      data: data,
      svg: svg,
      radius: radius,
      strokeColor: strokeColor,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.walls;
    const svg = d3
      .select(barWallRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    barChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      strokeColor: strokeColor,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.arteries;
    const svg = d3
      .select(barArteriesRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    barChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      strokeColor: strokeColor,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box
          sx={{
            position: "absolute",
          }}
        >
          <svg ref={histoAgeRef}></svg>
        </Box>
        <Box ref={histoAgeTooltipRef}></Box>
      </Box>
      <Box sx={{ mt: "50px", mb: "50px", ml: "50px" }}>
        <svg ref={pieGenderRef}></svg>
      </Box>
      <Box sx={{ mt: "50px", mb: "50px", ml: "50px" }}>
        <svg ref={pieInfarctRef}></svg>
      </Box>
      <Box sx={{ mt: "50px", mb: "100px", ml: "50px" }}>
        <svg ref={barWallRef}></svg>
      </Box>
      <Box sx={{ mt: "50px", mb: "100px", ml: "50px" }}>
        <svg ref={barArteriesRef}></svg>
      </Box>
    </Box>
  );
};

export default MIDemographicStatistics;
