import React from "react";
import { Box } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";
import ParameterCard from "../../../components/ParameterCard";

const parameters = [
  {
    id: "heightParam",
    name: "Height",
    dataType: "Number",
    description: "Patient's height in cm",
    virtual: false,
  },
  {
    id: "weightParam",
    name: "Weight",
    dataType: "Number",
    description: "Patient's weight in kg",
    virtual: false,
  },
  {
    id: "bmiParam",
    name: "BMI",
    dataType: "Number",
    description: "Patient's BMI",
    virtual: "weight/(height)²",
  },
  {
    id: "bmiGroupParam",
    name: "BMI Group",
    dataType: "Number",
    description: "Patient's BMI group",
    virtual: "Depending on BMI: 1:<18.5, 2:<25, 3:<30, 4:others",
  },
  {
    id: "bmiGroupTranslatedParam",
    name: "BMI Group",
    dataType: "String",
    description: "Patient's BMI group description",
    virtual:
      "Depending on BMI group: <18.5 (Underweight):1, 18-25 (Normal):2, 25-30 (Overweight):3, >30 (Obese):4",
  },
  {
    id: "nyhaParam",
    name: "NYHA",
    dataType: "Number",
    description: "Patient's NYHA class",
    virtual: false,
  },
  {
    id: "weeklyExerciseParam",
    name: "Average Weekly Exercise",
    dataType: "Number",
    description: "Patient's weekly average exercise in minutes",
    virtual: false,
  },
  {
    id: "weeklyExerciseGroupParam",
    name: "Average Weekly Exercise Group",
    dataType: "Number",
    description: "Patient's weekly average exercise group in minutes",
    virtual:
      "Depending on weekly average exercise: 1:<30, 2:<75, 3:<150, 4:<300, 5:<600, 6:others",
  },
  {
    id: "weeklyExerciseGroupTranslatedParam",
    name: "Average Weekly Exercise Group",
    dataType: "String",
    description:
      "Patient's weekly average exercise group description in minutes",
    virtual:
      "Depending on weekly average exercise group: <30:1, 30-75:2, 75-150:3, 150-300:4, 300-600:5, >600:6",
  },
  {
    id: "packYearsParam",
    name: "Cummulative Pack Years Smoked",
    dataType: "Number",
    description:
      "Pack Years smoked by the patient over his/her lifetime at entry",
    virtual: false,
  },
  {
    id: "packYearsGroupParam",
    name: "Cummulative Pack Years Smoked Group",
    dataType: "Number",
    description:
      "Pack Years smoked by the patient over his/her lifetime at entry group",
    virtual:
      "Depending on cummulative pack years: 1:<1, 2:<20, 3:<40, 4:others",
  },
  {
    id: "packYearsGroupTranslatedParam",
    name: "Cummulative Pack Years Smoked Group",
    dataType: "String",
    description:
      "Pack Years smoked by the patient over his/her lifetime at entry group description",
    virtual:
      "Depending on cummulative pack years group: <1 (Never Smoker):1, 1-20 (Light Smoker):2, 20-40 (Moderate Smoker):3, >40 (Heavy Smoker):4",
  },
  {
    id: "dailyAlcoholParam",
    name: "Daily Alcohol Consumption",
    dataType: "Number",
    description: "Average daily alcohol consumption (in drinks)",
    virtual: false,
  },
  {
    id: "weeklyAlcoholParam",
    name: "Weekly Alcohol Consumption",
    dataType: "Number",
    description: "Average weekly alcohol consumption",
    virtual: "7*{Daily Alcohol Consumption}",
  },
  {
    id: "weeklyAlcoholGroupParam",
    name: "Weekly Alcohol Consumption Group",
    dataType: "Number",
    description: "Average weekly alcohol consumption group",
    virtual:
      "Depending on weekly average alcohol consumption: 1:<1, 2:<15, 3:<30, 4:<50, 5:<80, 6:others",
  },
  {
    id: "weeklyAlcoholGroupTranslatedParam",
    name: "Weekly Alcohol Consumption Group",
    dataType: "String",
    description: "Average weekly alcohol consumption group description",
    virtual:
      "Depending on weekly average alcohol consumption group: <1 (Abstinence):1, 1-14 (Healthy Drinking):2, 15-30 (Low Risk Drinking):3, 30-50 (Medium Risk Drinking):4, 50-80 (High Risk Drinking):5, >80 (Very High Risk Drinking):6",
  },
];

const BodyCondition = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Basic Data"}
        subtitle={
          "Patient body condition & habits data recorded (underlined the questinaire parameters, no underline for the computed parameters)"
        }
      />
      <Box mt={"20px"}>
        {parameters.map((parameter, index) => {
          return <ParameterCard {...parameter} id={`ParameterCard${index}`} />;
        })}
      </Box>
    </Box>
  );
};

export default BodyCondition;
