import * as d3 from "d3";

export const pieChart = ({ data, svg, radius, strokeColor, strokeWidth, opacity, fontSize }) => {
    if (data === undefined) return;
    if (svg === undefined) return;
    if (radius === undefined) radius = 200;
    if (strokeColor === undefined) strokeColor = "white";
    if (strokeWidth === undefined) strokeWidth = "2px";
    if (opacity === undefined) opacity = 0.45;
    if (fontSize === undefined) fontSize = 17;

    const color = d3.scaleOrdinal().range(d3.schemeSet2);

    const pie = d3.pie().value(function (d) {
        return d[1];
    });
    const data_ready = pie(Object.entries(data));

    var arcGenerator = d3.arc().innerRadius(0).outerRadius(radius);

    svg
        .selectAll("mySlices")
        .data(data_ready)
        .join("path")
        .attr("d", arcGenerator)
        .attr("fill", function (d) {
            return color(d.data[0]);
        })
        .attr("stroke", strokeColor)
        .style("stroke-width", strokeWidth)
        .style("opacity", opacity);

    // Now add the annotation. Use the centroid method to get the best coordinates
    svg
        .selectAll("mySlices")
        .data(data_ready)
        .join("text")
        .text(function (d) {
            return `${d.data[0]}: ${d.data[1]}`;
        })
        .attr("transform", function (d) {
            return `translate(${arcGenerator.centroid(d)})`;
        })
        .style("text-anchor", "middle")
        .style("font-size", fontSize)
        .style("fill", strokeColor);
}

export const histogramChart = ({ data, totalPatients, svg, tooltip, width, height, binNo, xTitle, strokeColor, fill, opacity }) => {
    if (data === undefined) return;
    if (svg === undefined) return;
    if (totalPatients === undefined && tooltip !== undefined) return;
    if (width === undefined) width = 600;
    if (height === undefined) height = 400;
    if (binNo === undefined) binNo = 20;
    if (xTitle === undefined) xTitle = "";
    if (strokeColor === undefined) strokeColor = "white";
    if (fill === undefined) fill = "#69b3a2";
    if (opacity === undefined) opacity = 0.6;

    const precMinX = Number(Math.min(...data).toPrecision(2));
    const precMaxX = Number(Math.max(...data).toPrecision(2));
    const minMod = oom(precMinX);
    const maxMod = oom(precMaxX) * 0.5;
    const modMinX = Math.floor((precMinX / minMod) - 2) * minMod;
    const modMinX2 = roundDown5(modMinX);
    const minX = Math.min(...data) === 0 ? 0 : Math.max(modMinX2, 0);
    const maxX = Math.max(...data) + maxMod;

    // const minX = Math.min(...data) < 0.1 ? (Math.floor(100 * Math.min(...data))) / 100.0 : (Math.min(...data) < 1 ? (Math.floor(10 * Math.min(...data))) / 10.0 : Math.max((Math.min(...data) - Math.round(0.15 * (Math.max(...data) - Math.min(...data)))), 0));
    // const maxX = Math.max(...data) < 1 ? (Math.ceil(10 * Math.max(...data))) / 10.0 : (Math.max(...data) < 10 ? Math.ceil(Math.max(...data)) : Math.max(...data) + Math.round(0.1 * (Math.max(...data) - Math.min(...data))));

    //setup scaling
    const x = d3
        .scaleLinear()
        .domain([minX, maxX])
        .range([0, width]);
    svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));

    var histogram = d3
        .histogram()
        .value((d) => d)
        .domain(x.domain())
        .thresholds(x.ticks(binNo));
    var bins = histogram(data);

    var y = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, d3.max(bins, (d) => d.length)]);
    svg.append("g").call(d3.axisLeft(y));

    var showTooltip = (event, d) => { }
    var hideTooltip = (event, d) => { }

    if (tooltip !== undefined) {
        showTooltip = (event, d) => {
            tooltip.transition().duration(1).style("opacity", 1);
            tooltip.style("left", "10px").style("top", "0px").style("position", "relative")
                .style("width", "150px");
            tooltip.html(`Range: ${d.x0}-${d.x1}\nPatients: ${d.length}\nPercentage: ${Math.round(d.length / totalPatients * 100)}%`)
        }
        hideTooltip = (event, d) => {
            tooltip
                .transition()
                .duration(100)
                .style("opacity", 0)
        }
    }

    svg
        .selectAll("rect")
        .data(bins)
        .enter()
        .append("rect")
        .attr("x", 1)
        .attr("transform", (d) => `translate(${x(d.x0)}, ${y(d.length)})`)
        .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
        .attr("height", (d) => height - y(d.length))
        .style("fill", fill)
        .style("opacity", opacity)
        .on("mouseover", showTooltip)
        .on("mouseleave", hideTooltip);

    svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height + 40)
        .style("text-anchor", "middle")
        .style("font-size", "18px")
        .style("fill", strokeColor)
        .text(xTitle);
}

export const histogramDoubleChart = ({ data1, data2, svg, width, height, binNo, xTitle, title1, title2, strokeColor, fill1, fill2, opacity }) => {
    if (data1 === undefined) return;
    if (data2 === undefined) return;
    if (svg === undefined) return;
    if (width === undefined) width = 600;
    if (height === undefined) height = 400;
    if (binNo === undefined) binNo = 20;
    if (xTitle === undefined) xTitle = "";
    if (title1 === undefined) title1 = "variable A";
    if (title2 === undefined) title2 = "variable B";
    if (strokeColor === undefined) strokeColor = "white";
    if (fill1 === undefined) fill1 = "#69b3a2";
    if (fill2 === undefined) fill2 = "#fc8d62";
    if (opacity === undefined) opacity = 0.6;


    const dataTot = [...data1, ...data2];
    //setup scaling
    const x = d3
        .scaleLinear()
        .domain([Math.min(...dataTot) - Math.round(0.10 * Math.max(...dataTot)), Math.max(...dataTot) + Math.round(0.10 * Math.max(...dataTot))])
        .range([0, width]);
    svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));

    var histogram = d3
        .histogram()
        .value((d) => +d)
        .domain(x.domain())
        .thresholds(x.ticks(binNo));
    var bins1 = histogram(data1);
    var bins2 = histogram(data2);

    var y = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, Math.max(d3.max(bins1, (d) => d.length), d3.max(bins2, (d) => d.length))]);
    svg.append("g").call(d3.axisLeft(y));

    svg
        .selectAll("rect")
        .data(bins1)
        .join("rect")
        .attr("x", 1)
        .attr("transform", (d) => `translate(${x(d.x0)}, ${y(d.length)})`)
        .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
        .attr("height", (d) => height - y(d.length))
        .style("fill", fill1)
        .style("opacity", opacity)

    svg
        .selectAll("rect2")
        .data(bins2)
        .enter()
        .append("rect")
        .attr("x", 1)
        .attr("transform", (d) => `translate(${x(d.x0)}, ${y(d.length)})`)
        .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
        .attr("height", (d) => height - y(d.length))
        .style("fill", fill2)
        .style("opacity", opacity)

    svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height + 40)
        .style("text-anchor", "middle")
        .style("fill", strokeColor)
        .style("font-size", "18px")
        .text(xTitle);
    // Handmade legend
    svg.append("circle").attr("cx", ((3 * width / 4) + 20)).attr("cy", 30).attr("r", 6).style("fill", fill1)
    svg.append("circle").attr("cx", ((3 * width / 4) + 20)).attr("cy", 60).attr("r", 6).style("fill", fill2)
    svg.append("text").attr("x", ((3 * width / 4) + 40)).attr("y", 30).text(title1).style("font-size", "15px").style("fill", strokeColor).attr("alignment-baseline", "middle")
    svg.append("text").attr("x", ((3 * width / 4) + 40)).attr("y", 60).text(title2).style("font-size", "15px").style("fill", strokeColor).attr("alignment-baseline", "middle")


}

export const barChart = ({ data, svg, width, height, strokeColor, fill, opacity }) => {
    if (data === undefined) return;
    if (svg === undefined) return;
    if (width === undefined) width = 600;
    if (height === undefined) height = 400;
    if (strokeColor === undefined) strokeColor = "white";
    if (fill === undefined) fill = "#69b3a2";
    if (opacity === undefined) opacity = 0.6;

    let arrData = Object.values(data);
    let yMax = Math.max(...arrData);
    const dataArr = Object.entries(data);
    //setup scaling
    var x = d3
        .scaleBand()
        .range([0, width])
        .domain(Object.keys(data))
        .padding(0.2);
    svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .attr("transform", "translate(-10,0)rotate(-45)")
        .style("text-anchor", "end")
        .style("font-size", "18px");

    var y = d3.scaleLinear().domain([0, yMax]).range([height, 0]);
    svg.append("g").call(d3.axisLeft(y));

    svg
        .selectAll("mybar")
        .data(dataArr)
        .enter()
        .append("rect")
        .attr("x", function (d) {
            return x(d[0]);
        })
        .attr("y", function (d) {
            return y(d[1]);
        })
        .attr("width", x.bandwidth())
        .attr("height", function (d) {
            return height - y(d[1]);
        })
        .attr("fill", fill)
        .style("opacity", opacity);
}

export const densityChart = ({ data, svg, width, height, binNo, xTitle, strokeColor, fill, opacity, customMax, customMin, kernelWidth }) => {
    if (data === undefined) return;
    if (svg === undefined) return;
    if (width === undefined) width = 600;
    if (height === undefined) height = 400;
    if (binNo === undefined) binNo = 20;
    if (xTitle === undefined) xTitle = "";
    if (strokeColor === undefined) strokeColor = "white";
    if (fill === undefined) fill = "#69b3a2";
    if (opacity === undefined) opacity = 0.6;
    if (kernelWidth === undefined) kernelWidth = 7;

    const precMinX = Number(Math.min(...data).toPrecision(2));
    const precMaxX = Number(Math.max(...data).toPrecision(2));
    const minMod = oom(precMinX);
    const maxMod = oom(precMaxX) * 0.5;
    const modMinX = Math.floor((precMinX / minMod) - 2) * minMod;
    const modMinX2 = roundDown5(modMinX);
    const minX = customMin === undefined ? Math.min(...data) === 0 ? 0 : Math.max(modMinX2, 0) : customMin;
    const maxX = (customMax === undefined ? Math.max(...data) + 2.0 * maxMod : customMax);

    // const minX = Math.min(...data) < 0.1 ? (Math.floor(100 * Math.min(...data))) / 100.0 : (Math.min(...data) < 1 ? (Math.floor(10 * Math.min(...data))) / 10.0 : Math.max((Math.min(...data) - Math.round(0.15 * (Math.max(...data) - Math.min(...data)))), 0));
    // const maxX = Math.max(...data) < 1 ? (Math.ceil(10 * Math.max(...data))) / 10.0 : (Math.max(...data) < 10 ? Math.ceil(Math.max(...data)) : Math.max(...data) + Math.round(0.1 * (Math.max(...data) - Math.min(...data))));

    //setup scaling
    const x = d3
        .scaleLinear()
        .domain([minX, maxX])
        .range([0, width]);
    svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));

    const kde = kernelDensityEstimator(kernelEpanechnikov(kernelWidth), x.ticks(binNo))
    const density = kde(data)

    var y = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, d3.max(density, (d) => d[1])]);
    svg.append("g").call(d3.axisLeft(y));


    svg.append("path")
        .datum(density)
        .attr("fill", "none")
        .attr("stroke", fill)
        .attr("stroke-width", 5.5)
        .attr("stroke-linejoin", "round")
        .attr("d", d3.line()
            .curve(d3.curveBasis)
            .x(function (d) { return x(d[0]); })
            .y(function (d) { return y(d[1]); }));

    svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height + 40)
        .style("text-anchor", "middle")
        .style("font-size", "18px")
        .style("fill", strokeColor)
        .text(xTitle);
}

export const densityDoubleChart = ({ data1, data2, svg, width, height, binNo, xTitle, title1, title2, strokeColor, fill1, fill2, opacity, customMax, kernelWidth, customMin }) => {
    if (data1 === undefined) return;
    if (data2 === undefined) return;
    if (svg === undefined) return;
    if (width === undefined) width = 600;
    if (height === undefined) height = 400;
    if (binNo === undefined) binNo = 20;
    if (xTitle === undefined) xTitle = "";
    if (title1 === undefined) title1 = "variable A";
    if (title2 === undefined) title2 = "variable B";
    if (strokeColor === undefined) strokeColor = "white";
    if (fill1 === undefined) fill1 = "#69b3a2";
    if (fill2 === undefined) fill2 = "#fc8d62";
    if (opacity === undefined) opacity = 0.6;
    if (kernelWidth === undefined) kernelWidth = 7;


    const dataTot = [...data1, ...data2];

    const precMinX = Number(Math.min(...dataTot).toPrecision(2));
    const precMaxX = Number(Math.max(...dataTot).toPrecision(2));
    const minMod = oom(precMinX);
    const maxMod = oom(precMaxX) * 0.5;
    const modMinX = Math.floor((precMinX / minMod) - 2) * minMod;
    const modMinX2 = roundDown5(modMinX);
    const minX = customMin === undefined ? Math.min(...dataTot) === 0 ? 0 : Math.max(modMinX2, 0) : customMin;
    const maxX = (customMax === undefined ? Math.max(...dataTot) + 2.0 * maxMod : customMax);
    //setup scaling
    const x = d3
        .scaleLinear()
        .domain([minX, maxX])
        .range([0, width]);
    svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));

    const kde = kernelDensityEstimator(kernelEpanechnikov(kernelWidth), x.ticks(binNo));
    const density1 = kde(data1);
    const density2 = kde(data2);

    var y = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, Math.max(d3.max(density1, (d) => d[1]), d3.max(density2, (d) => d[1]))]);
    svg.append("g").call(d3.axisLeft(y));


    svg.append("path")
        .datum(density1)
        .attr("fill", "none")
        .attr("stroke", fill1)
        .attr("stroke-width", 5.5)
        .attr("stroke-linejoin", "round")
        .attr("d", d3.line()
            .curve(d3.curveBasis)
            .x(function (d) { return x(d[0]); })
            .y(function (d) { return y(d[1]); }));

    svg.append("path")
        .datum(density2)
        .attr("fill", "none")
        .attr("stroke", fill2)
        .attr("stroke-width", 5.5)
        .attr("stroke-linejoin", "round")
        .attr("d", d3.line()
            .curve(d3.curveBasis)
            .x(function (d) { return x(d[0]); })
            .y(function (d) { return y(d[1]); }));

    svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height + 40)
        .style("text-anchor", "middle")
        .style("font-size", "18px")
        .style("fill", strokeColor)
        .text(xTitle);


    // Handmade legend
    svg.append("circle").attr("cx", ((3 * width / 4) + 20)).attr("cy", 30).attr("r", 6).style("fill", fill1)
    svg.append("circle").attr("cx", ((3 * width / 4) + 20)).attr("cy", 60).attr("r", 6).style("fill", fill2)
    svg.append("text").attr("x", ((3 * width / 4) + 40)).attr("y", 30).text(title1).style("font-size", "15px").style("fill", strokeColor).attr("alignment-baseline", "middle")
    svg.append("text").attr("x", ((3 * width / 4) + 40)).attr("y", 60).text(title2).style("font-size", "15px").style("fill", strokeColor).attr("alignment-baseline", "middle")


}

function oom(n) {
    var order = Math.floor(Math.log(n) / Math.LN10
        + 0.000000001); // because float math sucks like that
    return Math.pow(10, order);
}

function roundDown5(x) {
    return Math.floor(x / 5) * 5;
}

function kernelDensityEstimator(kernel, X) {
    return function (V) {
        return X.map(function (x) {
            return [x, d3.mean(V, function (v) { return kernel(x - v); })];
        });
    };
}

function kernelEpanechnikov(k) {
    return function (v) {
        return Math.abs(v /= k) <= 1 ? 0.75 * (1 - v * v) / k : 0;
    };
}