import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth";
import { clearMessage, setMessage } from "../../redux/message";
import { useTheme } from "@mui/material";
import {
  Box,
  Grid,
  Link,
  Avatar,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";

const Login = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleLogin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const password = data.get("password");
    if (!username || !password) {
      setSuccessful(false);
      dispatch(setMessage("Both username and password are required"));
      return;
    }
    if (username.length < 3 || username.length > 20) {
      setSuccessful(false);
      dispatch(setMessage("Username must be between 3 and 20 characters long"));
      return;
    }
    if (password.length < 6 || password.length > 40) {
      setSuccessful(false);
      dispatch(setMessage("Password must be between 6 and 40 characters long"));
      return;
    }
    setLoading(true);
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
        navigate("/");
        window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary[100] }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        {message && (
          <Typography variant="h6" color={successful ? "green" : "red"}>
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Login;
