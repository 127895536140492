import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const ParameterCard = ({ id, name, description, dataType, virtual }) => {
  const theme = useTheme();
  return (
    <Box sx={{ p: 1, border: "1px dashed grey" }} id={id}>
      <Typography
        variant="h3"
        sx={{ textDecoration: virtual ? "" : "underline" }}
        color={theme.palette.secondary[200]}
        display={"inline"}
      >
        {name + " "}
      </Typography>
      <Typography
        variant="h3"
        sx={{ textDecoration: virtual ? "" : "underline" }}
        color={theme.palette.secondary[100]}
        display={"inline"}
      >
        ({dataType})
      </Typography>
      <Typography variant="h5" color={theme.palette.secondary[100]}>
        {description}
      </Typography>
      {virtual ? (
        <Typography variant="body2" color={theme.palette.neutral[300]}>
          {virtual}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ParameterCard;
