import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import UserService from "../../services/user.service";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  useTheme,
  Link,
  useMediaQuery,
} from "@mui/material";
import {
  Phone,
  Email,
  BiotechOutlined,
  LocationOn,
  Download,
  YouTube,
} from "@mui/icons-material";
import AboutCard from "../../components/AboutCard";
import AboutWithSubsCard from "../../components/AboutWithSubsCard";
import CV from "./CV.pdf";

const About = () => {
  const theme = useTheme();
  const [content, setContent] = useState("");
  const isNonMobile = useMediaQuery("(min-width: 890px)");

  useEffect(() => {
    UserService.getAboutContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  const handlePhone = () => {
    window.location.replace("tel:+30-281-039-4238");
  };

  const handleLocation = () => {
    window.open(
      "https://goo.gl/maps/AZosSgUZyzgGiodu8",
      "_blank",
      "noreferrer"
    );
  };

  const handleYT = () => {
    window.open(
      "https://www.youtube.com/@GiannisKypriotakis/videos",
      "_blank",
      "noreferrer"
    );
  };

  const handleCV = () => {
    const element = document.createElement("a");
    element.href = CV;
    element.target = "_blank";
    element.click();
  };

  const handleEmail = () => {
    window.location.replace("mailto:ikypriot@physics.uoc.gr");
  };

  const handleOrcid = () => {
    window.open(
      "https://orcid.org/0000-0002-3218-4030",
      "_blank",
      "noreferrer"
    );
  };

  return (
    <>
      {content && (
        <Box m="1.5rem 2.5rem">
          <Header title={content.title} subtitle={content.subtitle} />
          <Box mt={"40px"} height="80vh">
            <Box
              sx={{
                display: isNonMobile ? "flex" : "block",
                mb: "0.5rem",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="John Andrew Kypriotakis"
                src="https://www.gravatar.com/avatar/9d5a65c208a141d6b04afdf4440eb902?s=200"
                sx={{ width: 200, height: 200, mr: "0.5rem" }}
              />
              <List
                sx={{
                  maxWidth: 360,
                  borderRadius: 5,
                  bgcolor: theme.palette.background.alt,
                  ml: "0.5rem",
                }}
              >
                <ListItem key={"phone"}>
                  <ListItemAvatar>
                    <Avatar onClick={handlePhone} sx={{ cursor: "pointer" }}>
                      <Phone />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="+30-281-093-4238"
                    secondary="Mon-Fri 11-18 EEST"
                  />
                </ListItem>
                <ListItem key={"email"}>
                  <ListItemAvatar>
                    <Avatar onClick={handleEmail} sx={{ cursor: "pointer" }}>
                      <Email />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="ikypriot[at]physics.uoc.gr"
                    secondary="All the time"
                  />
                </ListItem>
                <ListItem key={"orcid"}>
                  <ListItemAvatar>
                    <Link></Link>
                    <Avatar onClick={handleOrcid} sx={{ cursor: "pointer" }}>
                      <BiotechOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="0000-0002-3218-4030"
                    secondary="ORCiD"
                  />
                </ListItem>
              </List>
              <List
                sx={{
                  maxWidth: 360,
                  borderRadius: 5,
                  bgcolor: theme.palette.background.alt,
                  ml: "0.5rem",
                }}
              >
                <ListItem key={"location"}>
                  <ListItemAvatar>
                    <Avatar onClick={handleLocation} sx={{ cursor: "pointer" }}>
                      <LocationOn />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Office 233, Physics Department"
                    secondary="University of Crete, Voutes Campus"
                  />
                </ListItem>
                <ListItem key={"youtube"}>
                  <ListItemAvatar>
                    <Avatar onClick={handleYT} sx={{ cursor: "pointer" }}>
                      <YouTube />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="@GiannisKypriotakis"
                    secondary="Youtube"
                  />
                </ListItem>
                <ListItem key={"cv"}>
                  <ListItemAvatar>
                    <Avatar onClick={handleCV} sx={{ cursor: "pointer" }}>
                      <Download />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Download my CV" secondary="PDF" />
                </ListItem>
              </List>
            </Box>
            <Box sx={{ display: isNonMobile ? "flex" : "block", mb: "0.5rem" }}>
              <Box m="0.5rem 0.5rem 0.5rem 0.5rem">
                <AboutCard title={"Education"} list={content.educItems} />
                <AboutCard title={"Reasearch"} list={content.researchItems} />
                <AboutCard title={"Teaching"} list={content.teachItems} />
              </Box>
              <Box m="0.5rem 0.5rem 0.5rem 1.5rem">
                <AboutCard title={"Languages"} list={content.langItems} />
                <AboutWithSubsCard
                  title={"Certifications"}
                  list={content.certItems}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default About;
