import React from "react";
import { Box } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";
import ParameterCard from "../../../components/ParameterCard";

const HeartScintigraphy = () => {
  const parameters = [
    {
      id: "apicalDamageParam",
      name: "Apical Damage",
      dataType: "Boolean",
      description:
        "Apical wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "apicalLateralDamageParam",
      name: "Apical Lateral Damage",
      dataType: "Boolean",
      description:
        "Apical Lateral wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "apicalSeptumDamageParam",
      name: "Apical Septum Damage",
      dataType: "Boolean",
      description:
        "Apical Septum wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "midAnterolateralDamageParam",
      name: "Mid Anterolateral Damage",
      dataType: "Boolean",
      description:
        "Mid Anterolateral wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "midInferoseptumDamageParam",
      name: "Mid Inferoseptum Damage",
      dataType: "Boolean",
      description:
        "Mid Inferoseptum wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "basalAnterolateralDamageParam",
      name: "Basal Anterolateral Damage",
      dataType: "Boolean",
      description:
        "Basal Anterolateral wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "basalInferoseptumDamageParam",
      name: "Basal Inferoseptum Damage",
      dataType: "Boolean",
      description:
        "Basal Inferoseptum wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "apicalInferiorDamageParam",
      name: "Apical Inferior Damage",
      dataType: "Boolean",
      description:
        "Apical Inferior wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "midInferiorDamageParam",
      name: "Mid Inferior Damage",
      dataType: "Boolean",
      description:
        "Mid Inferior wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "basalInferiorDamageParam",
      name: "Basal Inferior Damage",
      dataType: "Boolean",
      description:
        "Basal Inferior wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "apicalAnteriorDamageParam",
      name: "Apical Anterior Damage",
      dataType: "Boolean",
      description:
        "Apical Anterior wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "midAnteriorDamageParam",
      name: "Mid Anterior Damage",
      dataType: "Boolean",
      description:
        "Mid Anterior wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "basalAnteriorDamageParam",
      name: "Basal Anterior Damage",
      dataType: "Boolean",
      description:
        "Basal Anterior wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "midInferolateralDamageParam",
      name: "Mid Inferolateral Damage",
      dataType: "Boolean",
      description:
        "Mid Inferolateral wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "midAnteroseptumDamageParam",
      name: "Mid Anteroseptum Damage",
      dataType: "Boolean",
      description:
        "Mid Anteroseptum wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "basalInferolateralDamageParam",
      name: "Basal Inferolateral Damage",
      dataType: "Boolean",
      description:
        "Basal Inferolateral wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
    {
      id: "basalAnteroseptumDamageParam",
      name: "Basal Anteroseptum Damage",
      dataType: "Boolean",
      description:
        "Basal Anteroseptum wall non reversible damage as per the Myocardial Scitigraphy",
      virtual: false,
    },
  ];
  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Heart Scintigraphy"}
        subtitle={"Parameters recorded from the patient's Heart Scintigraphy"}
      />
      <Box mt={"20px"}>
        {parameters.map((parameter, index) => {
          return <ParameterCard {...parameter} id={`ParameterCard${index}`} />;
        })}
      </Box>
    </Box>
  );
};

export default HeartScintigraphy;
