import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ReviewField from "./ReviewField";
import ReviewFieldUnits from "./ReviewFieldUnits";

const MIReviewForm = ({ patient }) => {
  const theme = useTheme();
  //   const patient = {
  //     firstName: "Ιωάννης",
  //     lastName: "Κυπριωτάκης",
  //     dateOfBirth: new Date("1995-05-18"),
  //     gender: "M",
  //     AMKA: "18059500696",
  //     phone: "2810285773",
  //     infarctType: "STEMI",
  //     infarctYear: 2020,
  //     infarctAnterior: false,
  //     infarctInferior: true,
  //     infarctLateral: true,
  //     infarctPosterior: false,
  //     cbcRBC: 3.44,
  //     cbcHGB: 12.1,
  //     cbcHCT: 35.7,
  //     cbcPLT: 168,
  //     cbcWBC: 9,
  //     bioBNP: "",
  //     bioHBA1C: "",
  //     bioSGOT: 190,
  //     bioSGPT: 63,
  //     bioALP: 78,
  //     bioGGT: 138,
  //     bioCHOL: 194,
  //     bioTRIG: 186,
  //     bioHDL: 48,
  //     bioUA: 7.7,
  //     bioCREA: 1.04,
  //   };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "center",
          width: "80%",
        }}
      >
        <Typography variant="h3" color={theme.palette.primary[200]}>
          Demographics
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewField
          title="Name"
          content={patient.firstName + " " + patient.lastName}
        />
        <ReviewField
          title="Date of Birth"
          content={
            patient.dateOfBirth
              ? patient.dateOfBirth.toLocaleDateString("el-gr")
              : ""
          }
          ml={"20px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewField title="Gender" content={patient.gender} />
        <ReviewField title="AMKA" content={patient.AMKA} ml={"20px"} />
        <ReviewField title="Phone" content={patient.phone} ml={"20px"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "center",
          width: "80%",
          mt: "20px",
        }}
      >
        <Typography variant="h3" color={theme.palette.primary[200]}>
          Infarctology
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewField title="Infarct Year" content={patient.infarctYear} />
        <ReviewField
          title="Infarct Type"
          content={
            patient.infarctType +
            (patient.infarctAnterior ? " Anterior" : "") +
            (patient.infarctInferior ? " Inferior" : "") +
            (patient.infarctLateral ? " Lateral" : "") +
            (patient.infarctPosterior ? " Posterior" : "")
          }
          ml={"20px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "center",
          width: "80%",
          mt: "20px",
        }}
      >
        <Typography variant="h3" color={theme.palette.primary[200]}>
          CBC
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewFieldUnits
          title="RBC"
          content={patient.cbcRBC}
          units={["·10", <sup>12</sup>, "/L"]}
        />
        <ReviewFieldUnits
          title="HGB"
          content={patient.cbcHGB}
          units={"g/dL"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="HCT"
          content={patient.cbcHCT}
          units={"%"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="PLT"
          content={patient.cbcPLT}
          units={["·10", <sup>9</sup>, "/L"]}
          ml={"20px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewFieldUnits
          title="WBC"
          content={patient.cbcWBC}
          units={["·10", <sup>9</sup>, "/L"]}
        />
        <ReviewFieldUnits
          title="Ne"
          content={patient.cbcNe}
          units={["·10", <sup>9</sup>, "/L"]}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="Ly"
          content={patient.cbcLy}
          units={["·10", <sup>9</sup>, "/L"]}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="Mo"
          content={patient.cbcMo}
          units={["·10", <sup>9</sup>, "/L"]}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="Eos"
          content={patient.cbcEos}
          units={["·10", <sup>9</sup>, "/L"]}
          ml={"20px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "center",
          width: "80%",
          mt: "20px",
        }}
      >
        <Typography variant="h3" color={theme.palette.primary[200]}>
          Biochemistry
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewFieldUnits
          title="SGOT"
          content={patient.bioSGOT}
          units={"U/L"}
        />
        <ReviewFieldUnits
          title="SGPT"
          content={patient.bioSGPT}
          units={"U/L"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="γGT"
          content={patient.bioGGT}
          units={"U/L"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="ALP"
          content={patient.bioALP}
          units={"U/L"}
          ml={"20px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewFieldUnits
          title="CREAT"
          content={patient.bioCREA}
          units={"mg/dL"}
        />
        <ReviewFieldUnits
          title="UA"
          content={patient.bioUA}
          units={"mg/dL"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="BNP"
          content={patient.bioBNP}
          units={"pg/mL"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="HbA1c"
          content={patient.bioHBA1C}
          units={"%"}
          ml={"20px"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignContent: "flex-end",
          alignItems: "flex-end",
          width: "80%",
        }}
      >
        <ReviewFieldUnits
          title="Albumin"
          content={patient.bioAlbumin}
          units={"g/dL"}
        />
        <ReviewFieldUnits
          title="CHOL"
          content={patient.bioCHOL}
          units={"mg/dL"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="TG"
          content={patient.bioTRIG}
          units={"mg/dL"}
          ml={"20px"}
        />
        <ReviewFieldUnits
          title="HDL"
          content={patient.bioHDL}
          units={"mg/dL"}
          ml={"20px"}
        />
      </Box>
    </>
  );
};

export default MIReviewForm;
