import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import UserService from "../../services/user.service";
import { Box, Typography, useTheme } from "@mui/material";

const Home = () => {
  const [content, setContent] = useState("");
  const theme = useTheme();

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={content.title} subtitle={content.subtitle} />
      <Box mt={"40px"} height="80vh">
        <Typography
          variant={"h4"}
          align="justify"
          color={theme.palette.neutral[100]}
        >
          {content.content}
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
