import React from "react";
import { Typography, useTheme } from "@mui/material";

const ReviewField = ({ title, content, ml }) => {
  const theme = useTheme();
  const marl = ml ? ml : "0px";
  return (
    <>
      <Typography
        variant="h4"
        color={theme.palette.primary[100]}
        sx={{ ml: marl }}
      >
        {title}:
      </Typography>
      <Typography
        variant="h5"
        sx={{ ml: "5px" }}
        color={theme.palette.secondary[200]}
      >
        {content}
      </Typography>
    </>
  );
};

export default ReviewField;
