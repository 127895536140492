import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import dotenv from 'dotenv';

// dotenv.config({ path: "../.." });

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND + "measurement/" : process.env.REACT_APP_DEV_BACKEND + "measurement/";

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState }) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.accessToken) {
                headers.set("authorization", user.accessToken);
            }
            return headers;
        },
    }),
    reducerPath: "measurementApi",
    tagTypes: ["Measurements", "Locations", "Plot"],
    endpoints: (build) => ({
        getMeasurements: build.query({
            query: ({ page, pageSize, sort }) => ({
                url: "get",
                method: "GET",
                params: { page, pageSize, sort },
            }),
            providesTags: ["Measurements"],
        }),
        getLocations: build.query({
            query: () => "getLocs",
            providesTags: ["Locations"],
        }),
        getPlotData: build.query({
            query: ({ locid, startDate, endDate }) => ({
                url: "getPlot",
                method: "GET",
                params: { locid, startDate, endDate },
            }),
            providesTags: ["Plot"],
        }),
    })
})

export const {
    useGetMeasurementsQuery,
    useGetLocationsQuery,
    useGetPlotDataQuery
} = api;