import axios from "axios";
import authHeader from "./auth-header";
// import dotenv from 'dotenv';

// dotenv.config({ path: "../.." });

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND + "auth/" : process.env.REACT_APP_DEV_BACKEND + "auth/";

const register = (username, email, password) => {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password,
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "signin", {
            username,
            password,
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const update = (username, password, email, oldPassword) => {
    return axios
        .post(API_URL + "update", {
            username,
            password,
            email,
            oldPassword
        }, { headers: authHeader() })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const refresh = () => {
    return axios
        .post(API_URL + "refresh", {}, { headers: authHeader() })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const authService = {
    register,
    login,
    logout,
    update,
    refresh
};

export default authService;
