import React from "react";
import { Box } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";
import ParameterCard from "../../../components/ParameterCard";

const parameters = [
  {
    id: "firstNameParam",
    name: "First Name",
    dataType: "String",
    description: "Patient's first (given) name",
    virtual: false,
  },
  {
    id: "lastNameParam",
    name: "Last Name",
    dataType: "String",
    description: "Patient's last (family) name",
    virtual: false,
  },
  {
    id: "fullNameParam",
    name: "Full Name",
    dataType: "String",
    description: "Patient's full name",
    virtual: "{First Name} {Last Name}",
  },
  {
    id: "dateOfBirthParam",
    name: "Date of Birth",
    dataType: "Date",
    description: "Patient's date of birth",
    virtual: false,
  },
  {
    id: "ageAtEntryParam",
    name: "Age at Entry",
    dataType: "Number",
    description: "Patient's age at time of entry",
    virtual: "{Date at Entry} - {Date of Birth}",
  },
  {
    id: "ageGroupParam",
    name: "Age Group",
    dataType: "Number",
    description: "Patient's age group at time of entry",
    virtual: "Depending on age at entry: 1:<20, 2:<40, 3:<60, 4:<80, 5:others",
  },
  {
    id: "ageGroupTranslatedParam",
    name: "Age Group",
    dataType: "String",
    description: "Patient's age group description at time of entry",
    virtual:
      "Depending on age at entry: <20:1, 20-40:2, 40-60:3, 60-80:4, >80:5",
  },
  {
    id: "amkaParam",
    name: "AMKA",
    dataType: "String",
    description: "Patient's AMKA",
    virtual: false,
  },
  {
    id: "userParam",
    name: "User",
    dataType: "User",
    description: "User who entered patient",
    virtual: false,
  },
  {
    id: "phoneParam",
    name: "Phone",
    dataType: "String",
    description: "Patient's phone number",
    virtual: false,
  },
  {
    id: "genderParam",
    name: "Gender",
    dataType: "Boolean",
    description: "Patient's gender",
    virtual: false,
  },
  {
    id: "genderTranslatedParam",
    name: "Gender",
    dataType: "String",
    description: "Patient's gender description",
    virtual: "Depending on Gender Male:0, Female:1",
  },
  {
    id: "environmentParam",
    name: "Environment",
    dataType: "Number",
    description: "Patient's home setting",
    virtual: false,
  },
  {
    id: "environmentTranslatedParam",
    name: "Environment",
    dataType: "String",
    description: "Patient's home setting description",
    virtual: "Depending on Environment Urban:0, Semi-Urban:1, Rural:2",
  },
  {
    id: "ethnicityParam",
    name: "Ethnicity",
    dataType: "String",
    description: "Patient's ethnicity",
    virtual: false,
  },
  {
    id: "professionParam",
    name: "Profession",
    dataType: "Number",
    description: "Patient's profession",
    virtual: false,
  },
  {
    id: "professionTranslatedParam",
    name: "Profession",
    dataType: "String",
    description: "Patient's profession description",
    virtual: "Depending on Profession Office:0, Semi-Labor:1, Labor:2",
  },
];

const BasicData = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Basic Data"}
        subtitle={
          "Basic patient data recorded (underlined the questinaire parameters, no underline for the computed parameters)"
        }
      />
      <Box mt={"20px"}>
        {parameters.map((parameter, index) => {
          return <ParameterCard {...parameter} />;
        })}
      </Box>
    </Box>
  );
};

export default BasicData;
