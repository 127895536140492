import React from "react";
import { TextField, Box, InputAdornment } from "@mui/material";

const MICBCForm = ({ patient, handleChange }) => {
  return (
    <Box sx={{ mt: "20px" }}>
      <Box>
        <TextField
          label="RBC"
          placeholder="RBC"
          type="number"
          value={patient.cbcRBC}
          onChange={handleChange("cbcRBC")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">·10^12/L</InputAdornment>
            ),
          }}
          sx={{ width: "130px" }}
        />
        <TextField
          label="HGB"
          placeholder="HGB"
          type="number"
          value={patient.cbcHGB}
          onChange={handleChange("cbcHGB")}
          InputProps={{
            endAdornment: <InputAdornment position="end">g/dL</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="HCT"
          placeholder="HCT"
          type="number"
          value={patient.cbcHCT}
          onChange={handleChange("cbcHCT")}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="PLT"
          placeholder="PLT"
          type="number"
          value={patient.cbcPLT}
          onChange={handleChange("cbcPLT")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">·10^9/L</InputAdornment>
            ),
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
      </Box>
      <Box sx={{ mt: "20px" }}>
        <TextField
          label="WBC"
          placeholder="WBC"
          type="number"
          value={patient.cbcWBC}
          onChange={handleChange("cbcWBC")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">·10^9/L</InputAdornment>
            ),
          }}
          sx={{ width: "130px" }}
        />
        <TextField
          label="Ne"
          placeholder="Ne"
          type="number"
          value={patient.cbcNe}
          onChange={handleChange("cbcNe")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">·10^9/L</InputAdornment>
            ),
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="Ly"
          placeholder="Ly"
          type="number"
          value={patient.cbcLy}
          onChange={handleChange("cbcLy")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">·10^9/L</InputAdornment>
            ),
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="Mo"
          placeholder="Mo"
          type="number"
          value={patient.cbcMo}
          onChange={handleChange("cbcMo")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">·10^9/L</InputAdornment>
            ),
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="Eos"
          placeholder="Eos"
          type="number"
          value={patient.cbcEos}
          onChange={handleChange("cbcEos")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">·10^9/L</InputAdornment>
            ),
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
      </Box>
    </Box>
  );
};

export default MICBCForm;
