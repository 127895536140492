import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND + "mi/" : process.env.REACT_APP_DEV_BACKEND + "mi/";

export const miApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState }) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.accessToken) {
                headers.set("authorization", user.accessToken);
            }
            return headers;
        },
    }),
    reducerPath: "miApi",
    tagTypes: ["MIPatients", "MIStats"],
    endpoints: (build) => ({
        getPatients: build.query({
            query: ({ page, pageSize, sort }) => ({
                url: "get",
                method: "GET",
                params: { page, pageSize, sort },
            }),
            providesTags: ["MIPatients"],
        }),
        getStats: build.query({
            query: () => ({
                url: "stats",
                method: "GET",
            }),
            providesTags: ["MIStats"],
        }),
    })
})

export const {
    useGetPatientsQuery,
    useGetStatsQuery
} = miApi;