import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import * as d3 from "d3";
import { histogramChart, densityChart } from "../tools/plots";

const MILiverKidneyStatistics = ({ stats }) => {
  const theme = useTheme();
  const histoGFRRef = useRef();
  const histoFIB4Ref = useRef();
  const histoFIB5Ref = useRef();
  const histoSGOTRef = useRef();
  const histoSGPTRef = useRef();
  const histoPNIRef = useRef();
  const histoGFRTooltipRef = useRef();
  const histoFIB4TooltipRef = useRef();
  const histoFIB5TooltipRef = useRef();
  const histoSGOTTooltipRef = useRef();
  const histoSGPTTooltipRef = useRef();
  const histoPNITooltipRef = useRef();
  const [strokeColor, setStrokeColor] = useState("white");
  const width = 600;
  const height = 400;

  useEffect(() => {
    setStrokeColor(theme.palette.text.primary);
  }, [theme]);

  useEffect(() => {
    const data = stats.GFRs;
    const svg = d3
      .select(histoGFRRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoGFRTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "GFR (mL/min/1.73m^2)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "GFR (mL/min/1.73m^2)",
      strokeColor: strokeColor,
      customMax: 140,
      kernelWidth: 5,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.FIB4s;
    const svg = d3
      .select(histoFIB4Ref.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoFIB4TooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "FIB-4 Score",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 15,
      xTitle: "FIB-4 Score",
      strokeColor: strokeColor,
      customMax: 50,
      kernelWidth: 0.5,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.FIB5s;
    const svg = d3
      .select(histoFIB5Ref.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoFIB5TooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "FIB-5 Score",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 20,
      xTitle: "FIB-5 Score",
      strokeColor: strokeColor,
      customMax: 80,
      kernelWidth: 0.5,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.SGOTs;
    const svg = d3
      .select(histoSGOTRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoSGOTTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "SGOT (U/L)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 100,
      xTitle: "SGOT (U/L)",
      strokeColor: strokeColor,
      customMax: 8000,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.SGPTs;
    const svg = d3
      .select(histoSGPTRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoSGPTTooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "SGPT (U/L)",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 25,
      xTitle: "SGPT (U/L)",
      strokeColor: strokeColor,
      customMax: 1500,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  useEffect(() => {
    const data = stats.PNIs;
    const svg = d3
      .select(histoPNIRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");

    const tooltip = d3
      .select(histoPNITooltipRef.current)
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "black")
      .style("color", "white")
      .style("border-radius", "5px")
      .style("padding", "10px");

    // histogramChart({
    //   data: data,
    //   svg: svg,
    //   tooltip: tooltip,
    //   totalPatients: stats.totalPatients,
    //   width: width,
    //   height: height,
    //   binNo: 15,
    //   xTitle: "PNI",
    //   strokeColor: strokeColor,
    // });

    densityChart({
      data: data,
      svg: svg,
      width: width,
      height: height,
      binNo: 25,
      xTitle: "PNI",
      strokeColor: strokeColor,
      customMax: 80,
    });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [stats, strokeColor]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoGFRRef}></svg>
        </Box>
        <Box ref={histoGFRTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoFIB4Ref}></svg>
        </Box>
        <Box ref={histoFIB4TooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoFIB5Ref}></svg>
        </Box>
        <Box ref={histoFIB5TooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoSGOTRef}></svg>
        </Box>
        <Box ref={histoSGOTTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoSGPTRef}></svg>
        </Box>
        <Box ref={histoSGPTTooltipRef}></Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
          ml: "50px",
          width: width,
          height: height,
          display: "inline-block",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <svg ref={histoPNIRef}></svg>
        </Box>
        <Box ref={histoPNITooltipRef}></Box>
      </Box>
    </Box>
  );
};

export default MILiverKidneyStatistics;
