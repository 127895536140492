import React from "react";
import { TextField, Box, InputAdornment } from "@mui/material";

const MIBioForm = ({ patient, handleChange }) => {
  return (
    <Box sx={{ mt: "20px" }}>
      <Box>
        <TextField
          label="SGOT"
          placeholder="SGOT"
          type="number"
          value={patient.bioSGOT}
          onChange={handleChange("bioSGOT")}
          InputProps={{
            endAdornment: <InputAdornment position="end">U/L</InputAdornment>,
          }}
          sx={{ width: "130px" }}
        />
        <TextField
          label="SGPT"
          placeholder="SGPT"
          type="number"
          value={patient.bioSGPT}
          onChange={handleChange("bioSGPT")}
          InputProps={{
            endAdornment: <InputAdornment position="end">U/L</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="γGT"
          placeholder="γGT"
          type="number"
          value={patient.bioGGT}
          onChange={handleChange("bioGGT")}
          InputProps={{
            endAdornment: <InputAdornment position="end">U/L</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="ALP"
          placeholder="ALP"
          type="number"
          value={patient.bioALP}
          onChange={handleChange("bioALP")}
          InputProps={{
            endAdornment: <InputAdornment position="end">U/L</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
      </Box>
      <Box sx={{ mt: "20px" }}>
        <TextField
          label="BNP"
          placeholder="BNP"
          type="number"
          value={patient.bioBNP}
          onChange={handleChange("bioBNP")}
          InputProps={{
            endAdornment: <InputAdornment position="end">pg/mL</InputAdornment>,
          }}
          sx={{ width: "130px" }}
        />
        <TextField
          label="HbA1c"
          placeholder="HbA1c"
          type="number"
          value={patient.bioHBA1C}
          onChange={handleChange("bioHBA1C")}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
      </Box>
      <Box sx={{ mt: "20px" }}>
        <TextField
          label="Albumin"
          placeholder="Albumin"
          type="number"
          value={patient.bioAlbumin}
          onChange={handleChange("bioAlbumin")}
          InputProps={{
            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
          }}
          sx={{ width: "130px" }}
        />
        <TextField
          label="CREA"
          placeholder="CREA"
          type="number"
          value={patient.bioCREA}
          onChange={handleChange("bioCREA")}
          InputProps={{
            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="UA"
          placeholder="UA"
          type="number"
          value={patient.bioUA}
          onChange={handleChange("bioUA")}
          InputProps={{
            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
      </Box>
      <Box sx={{ mt: "20px" }}>
        <TextField
          label="CHOL"
          placeholder="CHOL"
          type="number"
          value={patient.bioCHOL}
          onChange={handleChange("bioCHOL")}
          InputProps={{
            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
          }}
          sx={{ width: "130px" }}
        />
        <TextField
          label="TRIG"
          placeholder="TRIG"
          type="number"
          value={patient.bioTRIG}
          onChange={handleChange("bioTRIG")}
          InputProps={{
            endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
        <TextField
          label="HDL"
          placeholder="HDL"
          type="number"
          value={patient.bioHDL}
          onChange={handleChange("bioHDL")}
          InputProps={{
            endAdornment: <InputAdornment position="end">ml/dL</InputAdornment>,
          }}
          sx={{ width: "130px", ml: "20px" }}
        />
      </Box>
    </Box>
  );
};

export default MIBioForm;
