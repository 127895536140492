import React, { useState } from "react";
import MIDemographicForm from "../../components/MIDemographicForm";
import MIInfarctForm from "../../components/MIInfarctForm";
import MICBCForm from "../../components/MICBCForm";
import MIBioForm from "../../components/MIBioForm";
import MIReviewForm from "../../components/MIReviewForm";
import Header from "../../components/Header";
import { Box, Typography, Button, Tabs, Tab, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { addPatient } from "../../services/mi.form.service";

const MIForm = () => {
  const theme = useTheme();
  const emptyPatient = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    AMKA: "",
    phone: "",
    infarctType: "",
    infarctYear: "",
    infarctArteries: "",
    infarctAnterior: false,
    infarctInferior: false,
    infarctLateral: false,
    infarctPosterior: false,
    cbcRBC: "",
    cbcHGB: "",
    cbcHCT: "",
    cbcPLT: "",
    cbcWBC: "",
    cbcNe: "",
    cbcLy: "",
    cbcMo: "",
    cbcEos: "",
    bioAlbumin: "",
    bioBNP: "",
    bioHBA1C: "",
    bioSGOT: "",
    bioSGPT: "",
    bioALP: "",
    bioGGT: "",
    bioCHOL: "",
    bioTRIG: "",
    bioHDL: "",
    bioUA: "",
    bioCREA: "",
  };
  const [patient, setPatient] = useState(emptyPatient);

  const [step, setStep] = useState(1);

  // const nextStep = (e) => {
  //   e.preventDefault();
  //   setStep((step) => step + 1);
  // };

  // const prevStep = (e) => {
  //   e.preventDefault();
  //   setStep((step) => step - 1);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(patient);
    addPatient(patient).then((status) => {
      if ((status.status === 200) | (status.status === 201)) {
        alert("Patient added successfully");
        setPatient(emptyPatient);
      } else {
        alert(status.data.message);
      }
    });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setPatient(emptyPatient);
  };

  const handleChange = (input) => (e) => {
    const updatedValue = { [input]: e.target.value };
    setPatient((patient) => ({
      ...patient,
      ...updatedValue,
    }));
  };

  const handleCheckChange = (input) => (e) => {
    const updatedValue = { [input]: e.target.checked };
    setPatient((patient) => ({
      ...patient,
      ...updatedValue,
    }));
  };

  const handleDateChange = (date) => {
    setPatient((patient) => ({
      ...patient,
      dateOfBirth: dayjs(date).toDate(),
    }));
  };

  const renderStep = (stepToRender) => {
    switch (stepToRender) {
      case 1:
        return (
          <MIDemographicForm
            patient={patient}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
          />
        );
      case 2:
        return (
          <MIInfarctForm
            patient={patient}
            handleChange={handleChange}
            handleCheckChange={handleCheckChange}
          />
        );
      case 3:
        return <MICBCForm patient={patient} handleChange={handleChange} />;
      case 4:
        return <MIBioForm patient={patient} handleChange={handleChange} />;
      case 5:
        return <MIReviewForm patient={patient} />;
      default:
        return <Typography variant="h4">Something went wrong</Typography>;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeStep = (event, newValue) => {
    setStep(newValue + 1);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={"MI Patient Add"}
        subtitle={"Use the form below to add a patient to the MI project"}
      />
      <Box
        mt={"40px"}
        height="35vh"
        width={"100%"}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          gridTemplateRows: "0.5fr repeat(8, 0.5fr) 0.5fr",
          gridColumnGap: "5px",
          gridRowGap: "5px",
        }}
      >
        <Box
          sx={{
            borderColor: "divider",
            gridArea: "1 / 1 / 2 / 7",
          }}
        >
          <Tabs
            value={step - 1}
            onChange={handleChangeStep}
            aria-label="basic tabs example"
            sx={{
              "& button.Mui-selected": { color: theme.palette.secondary[300] },
            }}
          >
            <Tab label="Demographic Data" {...a11yProps(0)} />
            <Tab label="Infarct Data" {...a11yProps(1)} />
            <Tab label="CBC Data" {...a11yProps(2)} />
            <Tab label="Biochemistry Data" {...a11yProps(3)} />
            <Tab label="Review Form" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <Box sx={{ gridArea: "2 / 1 / 10 / 7" }}>{renderStep(step)}</Box>
        {/* <Button
          sx={{ gridArea: "10 / 1 / 11 / 2" }}
          onClick={prevStep}
          disabled={step < 2}
        >
          {step > 1 ? "Previous" : ""}
        </Button> */}
        <Button sx={{ gridArea: "10 / 1 / 11 / 2" }} onClick={handleReset}>
          Reset
        </Button>
        <Button
          sx={{ gridArea: "10 / 2 / 11 / 3" }}
          onClick={handleSubmit}
          disabled={step !== 5}
        >
          Submit
        </Button>
        {/* <Button
          sx={{ gridArea: "10 / 6 / 11 / 7" }}
          onClick={nextStep}
          disabled={step > 4}
        >
          {step < 5 ? "Next" : ""}
        </Button> */}
      </Box>
    </Box>
  );
};

export default MIForm;
