import React from "react";
import {
  Box,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const AboutCard = ({ title, list }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h2" color={theme.palette.secondary[200]}>
        {title}
      </Typography>
      <List>
        {list.map((item) => (
          <ListItem key={`${title}:${item}`} disablePadding>
            <ListItemText
              disableTypography
              primary={<Typography variant="h4">{item}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AboutCard;
