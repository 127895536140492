import React from "react";
import { Box } from "@mui/material";
import ParameterHeader from "../../../components/ParameterHeader";
import ParameterCard from "../../../components/ParameterCard";

const CoronaryAngiography = () => {
  const parameters = [
    {
      id: "trunkStenParam",
      name: "LCA trunk stenosis",
      dataType: "Number",
      description: "Stenosis in the LCA trunk as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "ladProxStenParam",
      name: "Proximal LAD stenosis",
      dataType: "Number",
      description: "Stenosis in the proximal LAD as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "ladDistStenParam",
      name: "Distal LAD stenosis",
      dataType: "Number",
      description: "Stenosis in the distal LAD as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "d1StenParam",
      name: "D1 stenosis",
      dataType: "Number",
      description: "Stenosis in D1 as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "d2StenParam",
      name: "D2 stenosis",
      dataType: "Number",
      description: "Stenosis in D2 as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "lcxStenParam",
      name: "LCX stenosis",
      dataType: "Number",
      description: "Stenosis in LCX as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "m1StenParam",
      name: "M1 stenosis",
      dataType: "Number",
      description: "Stenosis in M1 as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "m2StenParam",
      name: "M2 stenosis",
      dataType: "Number",
      description: "Stenosis in M2 as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "rcaProxStenParam",
      name: "Proximal RCA stenosis",
      dataType: "Number",
      description: "Stenosis in the proximal RCA as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "rcaDistStenParam",
      name: "Distal RCA stenosis",
      dataType: "Number",
      description: "Stenosis in the distal RCA as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "amStenParam",
      name: "AM stenosis",
      dataType: "Number",
      description: "Stenosis in AM as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "pdaStenParam",
      name: "PDA stenosis",
      dataType: "Number",
      description: "Stenosis in PDA as measured in the CA (0-100)",
      virtual: false,
    },
    {
      id: "bypassParam",
      name: "Bypass",
      dataType: "Boolean",
      description: "Whether patient has received bypass surgery",
      virtual: false,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ParameterHeader
        title={"Coronary Angiography"}
        subtitle={"Parameters recorded from the patient's Coronary Angiography"}
      />
      <Box mt={"20px"}>
        {parameters.map((parameter, index) => {
          return <ParameterCard {...parameter} id={`ParameterCard${index}`} />;
        })}
      </Box>
    </Box>
  );
};

export default CoronaryAngiography;
