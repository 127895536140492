import React from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const MIDemographicForm = ({ patient, handleChange, handleDateChange }) => {
  return (
    <Box sx={{ mt: "20px" }}>
      <Box>
        <TextField
          placeholder="First Name"
          label="First Name"
          value={patient.firstName}
          onChange={handleChange("firstName")}
        />
        <TextField
          placeholder="Last Name"
          label="Last Name"
          value={patient.lastName}
          onChange={handleChange("lastName")}
          sx={{ ml: "20px" }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date of Birth"
            value={patient.dateOfBirth ? dayjs(patient.dateOfBirth) : null}
            onChange={(newValue) => handleDateChange(newValue)}
            sx={{ ml: "20px" }}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ mt: "20px" }}>
        <TextField
          placeholder="AMKA"
          label="AMKA"
          value={patient.AMKA}
          onChange={handleChange("AMKA")}
        />
        <TextField
          placeholder="Phone Number"
          label="Phone Number"
          value={patient.phone}
          onChange={handleChange("phone")}
          sx={{ ml: "20px" }}
        />
        <FormControl sx={{ ml: "20px", minWidth: "95px" }}>
          <InputLabel id="demo-simple-select-label">Gender</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={patient.gender}
            label="Gender"
            onChange={handleChange("gender")}
          >
            <MenuItem value={"M"}>Male</MenuItem>
            <MenuItem value={"F"}>Female</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default MIDemographicForm;
