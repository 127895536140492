import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../redux/auth";
import { clearMessage, setMessage } from "../../redux/message";
import { useTheme } from "@mui/material";
import { Box, Avatar, Button, TextField, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";

const Register = () => {
  const theme = useTheme();
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleRegister = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const email = data.get("email");
    const password = data.get("password");
    if (!username || !password) {
      setSuccessful(false);
      dispatch(setMessage("Both username and password are required"));
      return;
    }
    if (username.length < 3 || username.length > 20) {
      setSuccessful(false);
      dispatch(setMessage("Username must be between 3 and 20 characters long"));
      return;
    }
    if (password.length < 6 || password.length > 40) {
      setSuccessful(false);
      dispatch(setMessage("Password must be between 6 and 40 characters long"));
      return;
    }
    if (!email) {
      setSuccessful(false);
      dispatch(setMessage("Email is required"));
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setSuccessful(false);
      dispatch(setMessage("Email is wrong"));
      return;
    }
    setSuccessful(false);
    setLoading(true);
    dispatch(register({ username, email, password }))
      .unwrap()
      .then(() => {
        setSuccessful(true);
        setLoading(false);
      })
      .catch(() => {
        setSuccessful(false);
        setLoading(false);
      });
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary[100] }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        {!successful && (
          <Box
            component="form"
            onSubmit={handleRegister}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-Mail"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              Register
            </Button>
          </Box>
        )}
        {message && (
          <Typography variant="h6" color={successful ? "green" : "red"}>
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Register;
